import {
  Admin,
  Resource,
  fetchUtils,
  CustomRoutes,
  ExportButton,
} from "react-admin";
import { Route } from "react-router";

import dataprovider from "./providers/dataProvider";
import authProvider from "./providers/AuthProvider";
import { QueryClient } from "react-query";

import { Layout } from "./layout";
import { getItemWithExpiry } from "./utils/sessionData";
import config from "./config";

import "./App.css";

import Users from "./modules/users/Users";
import BugReports from "./modules/BugReports";
import Feedbacks from "./modules/Feedbacks";
import GameConfig from "./modules/GameConfig";
import LoginPage from "./modules/LoginPage";
import ReferralRecords from "./modules/ReferralRecords";
import WalletTransaction from "./modules/WalletTransactions";
import Configuration from "./modules/Configuaration";
import KycZoop from "./modules/KycZoop";
import Details from "./modules/users/Details";
import Withdrawals from "./modules/Withdrawals";
import Deposits from "./modules/deposits";
import GameHistory from "./modules/game-history/sp-game-history/GameHistory";
import GameRoundHistory from "./modules/game-history/sp-game-history/GameRoundHistory";
import ReGameRoundHistory from "./modules/game-history/re-game-history/RummyRoundHistory";
import IncompleteWithdrawal from "./modules/Incomplete-Withdrawal";
import IncompleteDeposits from "./modules/IncompleteDeposits";
import PaymentDashboard from "./modules/PaymentDashboard";
import GameplayDashboard from "./modules/GameplayDashboard";
import KycDashboard from "./modules/kyc/KycDashboard";
import GraphApp from "./modules/users/Graph";
import IncompleteJuspayWithdrawal from "./modules/Incomplete-juspay-withdrawal";
import ProcessingRequests from "./modules/ProcessingRequests";
import ProcessedRequests from "./modules/ProcessedRequests";
import BlockedUsers from "./modules/users/BlockedUsers";
import BlockedDevices from "./modules/users/BlockedDevices";
import AdminTransactions from "./modules/AdminTransactions";
import Coupon from "./modules/coupons/CreateCoupon";
import DailyReport from "./modules/DailyReport";
import CouponHistory from "./modules/coupons/CouponHistory";
import CouponDetails from "./modules/coupons/CouponDetails";
import EditCoupon from "./modules/coupons/EditCoupon";
// import TopWithdrawals from "./modules/TopWithdrawals";
// import TopDeposits from "./modules/TopDeposits";
import AdminPage from "./modules/profile";
import CbrGameHistory from "./modules/game-history/cbr-game-history/CbrGameHistory";
import ReGameHistory from "./modules/game-history/re-game-history/RummyGameHistory";
import LudoGameHistory from "./modules/game-history/ludo-game-history/LudoGameHistory";
import SpLiveGames from "./modules/game-history/sp-game-history/SpLiveGames";
import SnlLiveGames from "./modules/game-history/snl-game-history/SnlLiveGames";
import CbLiveGame from "./modules/game-history/cbr-game-history/CbrLiveGames";
import RummyLiveGames from "./modules/game-history/re-game-history/ReLiveGames";
import LudoLiveGame from "./modules/game-history/ludo-game-history/LudoLiveGames";
import SnlGameHistory from "./modules/game-history/snl-game-history/SnlGameHistory";
import EditTournament from "./modules/mega-tournaments/EditMegaTournament";
import ViewTournament from "./modules/tournaments/ViewTournament";
import Gametransactions from "./modules/Gametransaction";
import Gametables from "./modules/GameTable";
import Bonustransactions from "./modules/Bonustransaction";
import Referraltransactions from "./modules/ReferralTransaction";
import TaxDeductions from "./modules/TaxDeductions";
import ProfilePage from "./modules/profile";
import SpGameDashboard from "./modules/game-history/sp-game-history/SpGameDashboard";
import CallBreakGameDashboard from "./modules/game-history/cbr-game-history/CallBreakGameDashboard";
import SnakeAndLadderGameDashboard from "./modules/game-history/snl-game-history/SnakeAndLadderGameDashboard";
import AppVersionsDashboard from "./modules/AppVersionsDashboard";
import MegaTournaments from "./modules/mega-tournaments/MegaTournaments";
import CreateTournament from "./modules/tournaments/CreateTournament";
import CreateMegaTournament from "./modules/mega-tournaments/CreateMegaTournament";
import EditMegaTournament from "./modules/mega-tournaments/EditMegaTournament";
import Tournaments from "./modules/tournaments/Tournaments";
import ViewMegaTournament from "./modules/mega-tournaments/ViewMegaTournament";
import RevenueDashboard from "./modules/Revenue-Dashboard";
import UserStats from "./modules/users/UserStatistics";
import MegaTournamentDashboard from "./modules/mega-tournaments/MegaTournamentDashboard";
import RummyDashboard from "./modules/game-history/re-game-history/RummyDashboard";
import CouponPage from "./modules/couponss/CouponPage";
import ExportPage from "./modules/export";
import ConversionPage from "./modules/ConversionRate";
import EplGameHistory from "./modules/game-history/epl-game-history/EplGameHistory";
import HandCricketGameDashboard from "./modules/game-history/epl-game-history/HandCricketGameDashboard";
import EplLiveGames from "./modules/game-history/epl-game-history/EplLiveGames";
import Converts from "./modules/convert";
import AvtGameHistory from "./modules/game-history/avt-game-history/AvtGameHistory";
import AviatorGameDashboard from "./modules/game-history/avt-game-history/AviatorGameDashboard";
import AviatorLiveGames from "./modules/game-history/avt-game-history/AvtLiveGames";
function App() {
  const attachHeaders = (url: string, options: fetchUtils.Options = {}) => {
    const customHeaders = (options.headers ||
      new Headers({
        Accept: "application/json",
      })) as Headers;
    // Get token from storage
    const { token } = getItemWithExpiry("user");
    // Attach token in header - which will be added on all api requests
    customHeaders.set("Authorization", `Bearer ${token}`);
    options.headers = customHeaders;
    return fetchUtils.fetchJson(url, options);
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        structuralSharing: false,
        refetchOnWindowFocus: false,
      },
      mutations: {
        retryDelay: 10000,
      },
    },
  });

  return (
    <Admin
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataprovider(config.REST_ENDPOINT, attachHeaders)}
      requireAuth
      layout={Layout}
      queryClient={queryClient}
    >
      {(permissions) => (
        <>
          {(permissions.includes("admin") ||
            permissions.includes("finance")) && (
            <>
              <Resource name="profile" list={ProfilePage} />
              <Resource name="api/users/stats/counts" list={UserStats} />
              <Resource name="api/sp-game-history" list={GameHistory} />
              <Resource name="api/re-round-history" list={ReGameHistory} />

              <Resource name="api/cbr-game-history" list={CbrGameHistory} />
              <Resource name="api/game-table/ludo-history" list={Gametables} />
              <Resource name="api/snl-game-history" list={SnlGameHistory} />
              <Resource name="gameplay-dashboard" list={GameplayDashboard} />
              <Resource name="api/sp/dashboard" list={SpGameDashboard} />
              <Resource
                name="api/callbreak-dashboard"
                list={CallBreakGameDashboard}
              />
              <Resource
                name="api/snakeandladder-dashboard"
                list={SnakeAndLadderGameDashboard}
              />
              <Resource
                name="api/hancricket-dashboard"
                list={HandCricketGameDashboard}
              />
              <Resource
                name="api/mega-tournament/dashboard/stats"
                list={MegaTournamentDashboard}
              />
              <Resource name="api/mega-tournament" list={MegaTournaments} />
              <Resource
                name="api/transactions/admin-transaction"
                list={AdminTransactions}
              />
              <Resource name="api/payment/deposit" list={Deposits} />
              <Resource name="api/payment/withdrawal" list={Withdrawals} />
              <Resource name="api/payment/convert" list={Converts} />
              <Resource
                name="api/payment/tax-deductions"
                list={TaxDeductions}
              />
              <Resource name="api/payment/dashboard" list={PaymentDashboard} />
              <Resource
                name="api/users/revenue/report"
                list={GameplayDashboard}
              />
              <Resource
                name="api/payment/revenue-dashboard"
                list={RevenueDashboard}
              />
              <Resource name="api/kyc" list={KycZoop} />
            </>
          )}

          {(permissions.includes("admin") ||
            permissions.includes("support")) && (
            <>
              {/* <Resource name="profile" list={ProfilePage} /> */}
              <Resource name="api/users/stats/counts" list={UserStats} />
              <Resource
                name="api/sp-game-history"
                list={GameHistory}
                show={Details}
              />

              <Resource
                name="api/cbr-game-history"
                list={CbrGameHistory}
                show={Details}
              />
              <Resource
                name="api/re-round-history"
                list={ReGameHistory}
                show={Details}
              />

              <Resource
                name="api/ludo-game-history"
                list={LudoGameHistory}
                show={Details}
              />
              <Resource
                name="api/snl-game-history"
                list={SnlGameHistory}
                show={Details}
              />
              <Resource
                name="api/epl-game-history"
                list={EplGameHistory}
                show={Details}
              />
              <Resource
                name="api/aviator-game-history"
                list={AvtGameHistory}
                show={Details}
              />
              <CustomRoutes>
                <Route
                  path="api/sp-game-history/:tableId"
                  element={<GameRoundHistory />}
                />
              </CustomRoutes>
              <CustomRoutes>
                <Route
                  path="api/re-round-history/:tableId"
                  element={<ReGameRoundHistory />}
                />
              </CustomRoutes>
              <Resource name="kyc-records" list={KycZoop} />

              <Resource
                name="api/transactions/bonus-transaction"
                list={Bonustransactions}
              />
              <Resource name="api/game-table/ludo-history" list={Gametables} />
              <Resource
                name="api/users/referral/transaction"
                list={Referraltransactions}
              />

              <Resource name="api/kyc" list={KycZoop} />
              <Resource name="api/payment/deposit" list={Deposits} />
              <Resource name="api/payment/convert" list={Converts} />
              <Resource name="api/payment/withdrawal" list={Withdrawals} />
              {/* <Resource
                name="api/payment/top-withdrawal"
                list={TopWithdrawals}
              /> */}
              {/* <Resource name="api/payment/top-deposit" list={TopDeposits} /> */}
              <Resource name="api/payment" list={WalletTransaction} />
            </>
          )}

          {(permissions.includes("admin") ||
            permissions.includes("developer")) && (
            <>
              <CustomRoutes>
                <Route path="/" element={<GameConfig />} />
                <Route path="/configuration" element={<Configuration />} />
                <Route
                  path="api/users/appVersions/dashboard"
                  element={<AppVersionsDashboard />}
                />
                <Route path="api/game-config" element={<GameConfig />} />
              </CustomRoutes>
            </>
          )}

          {permissions.includes("admin") && (
            <>
              {/* <Resource name="profile" list={ProfilePage} /> */}
              <Resource
                name="api/payment/revenue-dashboard"
                list={RevenueDashboard}
              />
              <Resource name="api/users" list={Users} show={Details} />
              <Resource name="api/users/stats/counts" list={UserStats} />
              <Resource name="dashboard" list={GameplayDashboard} />
              <Resource name="users-graph" list={GraphApp} />
              <Resource name="users/blocked" list={BlockedUsers} />
              <Resource
                name="api/transactions/admin-transaction"
                list={AdminTransactions}
              />
              <Resource name="blocked-device" list={BlockedDevices} />
              <Resource name="kyc-dashboard" list={KycDashboard} />
              <Resource name="gameplay-dashboard" list={GameplayDashboard} />
              <Resource name="api/sp/dashboard" list={SpGameDashboard} />
              <Resource
                name="api/callbreak-dashboard"
                list={CallBreakGameDashboard}
              />
              <Resource
                name="api/snakeandladder-dashboard"
                list={SnakeAndLadderGameDashboard}
              />
              <Resource
                name="api/aviator-dashboard"
                list={AviatorGameDashboard}
              />
                <Resource
                name="api/hancricket-dashboard"
                list={HandCricketGameDashboard}
              />
              <Resource name="admin/live-games/skillpatti" list={SpLiveGames} />
              <Resource name="admin/live-games/callbreak" list={CbLiveGame} />
              <Resource
                name="admin/live-games/snakesandladders"
                list={SnlLiveGames}
              />
              <Resource
                name="admin/live-games/rummyempire"
                list={RummyLiveGames}
              />
              <Resource
                name="admin/live-games/aviator"
                list={AviatorLiveGames}
              />
              <Resource
                name="admin/live-games/handcricket"
                list={EplLiveGames}
              />
              <Resource name="api/rummy/dashboard" list={RummyDashboard} />
              <Resource name="bug-reports" list={BugReports} />
              <Resource name="feedbacks" list={Feedbacks} />
              <Resource name="referrals" list={ReferralRecords} />
              <Resource
                name="api/payment/tax-deductions"
                list={TaxDeductions}
              />

              {/* <Resource
                name="api/payment/top-withdrawal"
                list={TopWithdrawals}
              /> */}
              {/* <Resource name="api/payment/top-deposit" list={TopDeposits} /> */}
              <Resource name="api/game-config" list={GameConfig} />
              <Resource name="coupons" list={Coupon} />
              <Resource
                name="/ludo/tournament"
                list={CreateTournament}
                edit={EditTournament}
                show={ViewTournament}
              />
              <Resource
                name="/ludo/mega-tournament"
                list={CreateMegaTournament}
                edit={EditMegaTournament}
                show={ViewMegaTournament}
              />
              <Resource name="/payment/conversion-rate" list={ConversionPage} />

              <Resource
                name="api/Coupon/coupon-history"
                list={CouponHistory}
                show={CouponDetails}
                edit={EditCoupon}
              />

              <Resource
                name="/promo/coupons"
                list={CouponPage}
                show={CouponDetails}
                edit={EditCoupon}
              />
              <CustomRoutes>
                <Route
                  path="/promo/coupons/:promoCode"
                  element={<CouponDetails />}
                />
              </CustomRoutes>
              <CustomRoutes>
                <Route path="/" element={<GameplayDashboard />} />
                <Route path="/daily-report" element={<DailyReport />} />
              </CustomRoutes>

              <Resource name="api/tournament" list={Tournaments} />
              <Resource name="api/mega-tournament" list={MegaTournaments} />
              <Resource
                name="api/mega-tournament/dashboard/stats"
                list={MegaTournamentDashboard}
              />
              <Resource name="api/tasks/create" list={ExportPage} />
            </>
          )}
        </>
      )}
    </Admin>
  );
}

export default App;
