import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, usePermissions, useRefresh } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import { ReactQueryDevtools } from "react-query/devtools";
import config from "../../../config";
import { getItemWithExpiry } from "../../../utils";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Big from "big.js";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  Switch,
} from "@mui/material";
import { callRestApi } from "../../../utils/callRestApi";

const GameOngoing = () => {
  const refresh = useRefresh();
  const [loading, setLoading] = useState(true);
  const [tableIdFilter, setTableIdFilter] = useState("");
  const [usernameFilter, setUsernameFilter] = useState("");
  const [amountFilter, setAmountFilter] = useState("");
  const [totalLivePlayers, setTotalLivePlayers] = useState(0);
  const [totalTables, settotalTables] = useState(0);
  const [uniqueTableIds, setUniqueTableIds] = useState(0);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [tableStatus, setTableStatus] = useState("green");
  const [stuckTables, setStuckTables] = useState<string[]>([]);
  const [clearConfirmation, setClearConfirmation] = useState(false);
  const [waitingTableCount, setWaitingTableCount] = useState(0);
  const [waitingPlayerCount, setWaitingPlayerCount] = useState(0);
  const [showWaitingTables, setShowWaitingTables] = useState(false);
  const [showStuckTables, setShowStuckTables] = useState(false);
  const [tableIdToDelete, setTableIdToDelete] = useState(null);
  const [stuckTablesCount, setStuckTablesCount] = useState(0);
  const { permissions } = usePermissions();
  const isAdmin = permissions.includes("admin");

  const openClearConfirmation = (tableId: any) => {
    setTableIdToDelete(tableId);
    setClearConfirmation(true);
  };

  const closeClearConfirmation = () => {
    setClearConfirmation(false);
  };

  const handleClearConfirmation = async (confirmed: boolean) => {
    closeClearConfirmation();
    if (confirmed && tableIdToDelete) {
      await handleClearStuckTable(tableIdToDelete);
    }
  };

  const handleClearStuckTable = async (tableId: string) => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }
      // eslint-disable-next-line
      const endpoint = `/admin/live-games/callbreak/${tableId}`;
      await callRestApi(endpoint, "DELETE", {});

      toast.success("Table deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      refresh();
    } catch (e: any) {
      toast(
        e?.response.data?.message ? e?.response.data?.message : e?.message,
        { type: "error" }
      );
    }
  };

  const breakpoints = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
  };
  const columns = [
    {
      field: "tableId",
      headerName: "Table ID",
      flex: breakpoints.sm,
      renderCell: (params: any) => (
        <div>
          <Link
            to={params?.row.tableId}
            style={{
              color: stuckTables.includes(params?.row.tableId) ? "red" : "",
            }}
          >
            {params.row.tableId}
          </Link>
          {stuckTables.some((table: any) => table.tableId === params?.row.tableId) &&
            renderClearButton(params.row.tableId)}
        </div>
      ),
    },
    {
      field: "gameStatus",
      headerName: "Table Status",
      flex: breakpoints.sm,
      renderCell: (params: any) => (
        <div
          style={{
            backgroundColor: "darkcyan",
            borderRadius: "5px",
            padding: "5px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "white",
              fontWeight: "bold",
            }}
          >
            {params.row.gameStatus}
          </span>
        </div>
      ),
    },
    { field: "tableAmount", headerName: "Join Amount", flex: breakpoints.sm },
    {
      field: "rounds",
      headerName: "Rounds",
      flex: breakpoints.sm,
    },
    {
      field: "players",
      headerName: "Players",
      flex: breakpoints.sm,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                padding: "5px",
              }}
            >
              <Link to={`/api/users/${player.userId}/show`}>
                {player.username}
              </Link>
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "playerStatus",
      headerName: "Player Status",
      flex: breakpoints.sm,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{ color: player.active ? "green" : "red", padding: "5px" }}
            >
              {player.active ? "Active" : "Inactive"}
            </div>
          ))}
        </div>
      ),
    },
  ];

  const rows = filteredData
    ? filteredData.reduce((accumulator: any[], game: any) => {
      const existingTable = accumulator.find(
        (item) => item.tableId === game.tableId
      );

      if (existingTable) {
        existingTable.players.push(...game.players);
      } else {
        accumulator.push({
          id: game.tableId,
          tableId: game.tableId,
          gameStatus: game.gameStatus,
          tableAmount: game.tableType.amount,
          rounds: `${game.roundNo} / ${game.totalRounds}`,
          players: [...game.players],
        });
      }

      return accumulator;
    }, [])
    : [];


  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const fetchGameData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      const filters = {
        userName: usernameFilter,
        tableId: tableIdFilter,
        amount: amountFilter
      };

      const { page, pageSize } = paginationModel;
      let query = "";
      query += `skip=${pageSize * page}&count=${pageSize}`;
      const endpoint = "/admin/live-games/callbreak";
      const response = await callRestApi(endpoint, "POST", filters, query);

      if (!response?.data || Object.keys(response.data).length === 0) {
        setLoading(false);
        return; 
      }

      setUniqueTableIds(response?.data.tableCount);
      setTotalLivePlayers(response?.data.playerCount);
      settotalTables(response?.data.tableCount);
      setWaitingTableCount(response?.data.waitingTableCount);
      setWaitingPlayerCount(response?.data.waitingUserCount);
      setStuckTables(response?.data.stuckTables);
      setStuckTablesCount(response?.data.stuckTableCount);
      let filteredData: any[] = [
        ...response?.data.gameTables,
        ...response?.data.waitingTables,
      ];
      if (showWaitingTables && showStuckTables) {
        filteredData = [
          ...response?.data.waitingTables.filter((table: any) =>
            stuckTables.includes(table.tableId)
          ),
        ];
        settotalTables(filteredData.length);
      } else if (showWaitingTables) {
        filteredData = response?.data.waitingTables;
        settotalTables(filteredData.length);
      } else if (showStuckTables) {
        const stuckTablesData = [
          ...response?.data.stuckTables
        ];
        settotalTables(stuckTablesCount);
        filteredData = stuckTablesData;
      }

      if (usernameFilter || amountFilter || tableIdFilter) {
        filteredData = [
          ...response?.data.gameTables
        ];
        settotalTables(filteredData.length)
      }

      setFilteredData(filteredData);

      if (response?.data.tableCount > 0) {
        setTableStatus("green");
      } else {
        setTableStatus("red");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching game data:", error);
      setLoading(false);
      toast.error("Error fetching game data");
    }
  };

  useEffect(() => {
    let interval: any;
    const timeout = setTimeout(() => {
      fetchGameData();
      interval = setInterval(async () => {
        await fetchGameData();
        refresh();
      }, 20000);
    }, 800);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [
    refresh,
    tableIdFilter,
    usernameFilter,
    amountFilter,
    showWaitingTables,
    showStuckTables,
    paginationModel,
  ]);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const renderClearButton = (tableId: any) => {
    const buttonStyle = {
      marginLeft: "28px",
      padding: "6px 6px",
      backgroundColor: "#FF6500",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    };

    return isAdmin ? (
      <div>
        <button
          ref={buttonRef}
          onClick={() => openClearConfirmation(tableId)}
          style={buttonStyle}
        >
          Clear
        </button>
        <Dialog open={clearConfirmation} onClose={closeClearConfirmation}>
          <DialogTitle>Confirm Clear Table</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to clear the table?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClearConfirmation(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleClearConfirmation(true)}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : null;
  };
  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Callbreak Live Games</h2>

      <div>
        <div>
          <div
            style={{
              display: "inline-block",
              marginRight: "10px",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "80%",
                backgroundColor: tableStatus,
                display: "inline-block",
                marginRight: "5px",
              }}
            />
            <b> Live Tables: {uniqueTableIds} </b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "80%",
                  backgroundColor: tableStatus,
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              <b> Live Players: {totalLivePlayers} </b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "80%",
                  backgroundColor: tableStatus,
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              <b> Waiting Tables: {waitingTableCount} </b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "80%",
                  backgroundColor: tableStatus,
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              <b> Waiting Players: {waitingPlayerCount} </b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                  marginTop: "8px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "80%",
                    backgroundColor: stuckTablesCount > 0 ? "red" : "green",
                    display: "inline-block",
                    marginRight: "5px",
                  }}
                />
                <b> Stuck Tables: {stuckTablesCount} </b>
              </div>
            </div>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Search by Table ID"
                variant="outlined"
                fullWidth
                value={tableIdFilter}
                onChange={(e) => {
                  setPaginationModel({ page: 0, pageSize: 10 });
                  setTableIdFilter(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Search by UserName"
                variant="outlined"
                fullWidth
                value={usernameFilter}
                onChange={(e) => {
                  setPaginationModel({ page: 0, pageSize: 10 });
                  setUsernameFilter(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Search by Join Amount"
                variant="outlined"
                fullWidth
                value={amountFilter}
                inputProps={{ minLength: 10 }}
                onChange={(e) => {
                  setPaginationModel({ page: 0, pageSize: 10 });
                  setAmountFilter(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 0px 6px 0px",
              }}
            >
              <FormLabel component="legend">Show Waiting Tables</FormLabel>
              <Switch
                checked={showWaitingTables}
                onChange={() => setShowWaitingTables((prev) => !prev)}
              />
              <FormLabel component="legend">Show Stuck Tables</FormLabel>
              <Switch
                checked={showStuckTables}
                onChange={() => setShowStuckTables((prev) => !prev)}
              />
            </Grid>
            <DataGrid
              columns={columns}
              rows={rows}
              loading={loading}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[10, 20, 50]}
              rowCount={totalTables}
              paginationMode="server"
              autoHeight
              rowHeight={150}
            />
          </Paper>
          <ReactQueryDevtools />
        </div>
      </div>
    </>
  );
};

export default GameOngoing;
