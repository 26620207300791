import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "./sessionData";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

export const callRestApi = async (
  endpoint: string,
  method: string = "GET",
  body: object = {},
  query: string = ""
) => {
  const user = getItemWithExpiry("user");
  if (!user.token) {
    toast.error("You have no access");
    return;
  }

  let url: string = `${config.REST_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;

  if (endpoint === "/ludo/tournament") {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint === '/admin/live-games/callbreak') {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`
  }
  if (endpoint === '/admin/live-games/snakesandladders') {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`
  }
  if (endpoint === '/admin/live-games/aviator') {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`
  }
  if (endpoint === '/admin/live-games/handcricket') {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`
  }
  if (endpoint.startsWith("/admin/live-games/skillpatti")) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint === "/ludo/tournament/660be03a656f9f95e7846787") {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint === `/ludo/tournament/661637744b55481fa161a8af/cancel`) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint === '/users/change-block-status') {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint === '/transactions/admin-refund') {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint === '/users/roles') {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint === '/promo/coupons') {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint === '/payment/generate-invoice') {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint.startsWith('/admin/live-games/callbreak')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint.startsWith('/admin/live-games/aviator')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint.startsWith('/admin/live-games/snakesandladders')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`
  }
  if (endpoint.startsWith('/ludo/mega-tournament')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint.startsWith('/promo/coupons')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint.startsWith('/payment/conversion-rate')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint.startsWith('/users/delete-kyc')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  
  if (endpoint.startsWith("/admin/live-games/rummyempire")) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint.startsWith("/admin/live-games/handcricket")) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint.startsWith('/ludo/mega-tournament/${id}/cancel')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if (endpoint.startsWith('/ludo/mega-tournament/${id}/force-end')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  }
  if(endpoint.startsWith('/payment/payout/manual-review')) {
    url = `${config.AUTH_ENDPOINT}${endpoint}&key=${config.MAINTENANCE_BYPASS_KEY}`;
  }

  if (!isEmpty(query)) {
    url = `${url}&${query}`;
  }

  return await axios({
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    method,
    url,
    data: body,
  });
};