import React from "react";
import { Link, useRecordContext } from "react-admin";

const UserNameHyperlink = (props: any) => {
  const contextRecord = useRecordContext();
  const record = props.record || contextRecord;

  if (!record || !record[props.source]) {
    return null;
  }

  return (
    <Link to={`/api/users/${record[props.to]}/show`}>
      {record[props.source]}
    </Link>
  );
};

UserNameHyperlink.defaultProps = {
  source: "userName",
  to: "userId",
};

export default UserNameHyperlink;
