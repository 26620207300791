import React, { useState, useCallback } from "react";
import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
  useNotify,
} from "react-admin";
import { Stack, Typography, Button, Box } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";
import { getItemWithExpiry } from "../utils";
import { callRestApi } from "../utils/callRestApi";

const Withdrawals = (props: object) => {
  const { permissions } = usePermissions();
  const [verificationData, setVerificationData] = useState<Record<string, any>>(
    {}
  );
  const notify = useNotify();

  const handleVerifyClick = useCallback(async (orderId: string) => {
    try {
      const endpoint = `/payment/payout/manual-review/verify?orderId=${orderId}`;
      const response = await callRestApi(endpoint, "GET", {});

      setVerificationData((prevData) => ({
        ...prevData,
        [orderId]: response?.data,
      }));
    } catch (error) {
      console.error("Error verifying names:", error);
      setVerificationData((prevData) => ({
        ...prevData,
        [orderId]: { kycName: "", accountHolderName: "" ,reason: "" },
      }));
    }
  }, []);

  const handleActionClick = useCallback(
    async (orderId: string, action: "approve" | "reject") => {
      try {
        const user = getItemWithExpiry("user");
        if (!user || !user.token) {
          notify("You have no access", { type: "error" });
          return;
        }

        const endpoint = `/payment/payout/manual-review/${action}?orderId=${orderId}`;
        await callRestApi(endpoint, "GET", {});

        notify(
          `${action === "approve" ? "Approved" : "Rejected"} successfully`,
          { type: "success" }
        );

        window.location.reload();
      } catch (error) {
        console.error(`Error ${action}ing payout:`, error);
        notify(`Failed to ${action} payout`, { type: "error" });
      }
    },
    [notify]
  );

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "success", name: "Success" },
        { id: "failed", name: "Failed" },
        { id: "pending", name: "Pending" },
        { id: "refund", name: "Refund" },
        { id: "manualReview", name: "Manual Review" },
      ]}
    />,
    <SelectArrayInput
      label="Filter by Gateway"
      source="gateway"
      choices={[
        { id: "juspay", name: "Juspay" },
        { id: "cashfree", name: "Cashfree" },
      ]}
    />,
    <SelectArrayInput
      label="Filter by PayoutType"
      source="payoutType"
      choices={[
        { id: "UPI", name: "UPI" },
        { id: "IMPS", name: "IMPS" },
      ]}
    />,
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Amount"
        source="amount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Withdrawals</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <TextField source="_id" label="Beneficiary Id" />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="gateway" />
          <TextField source="payoutType" />
          <TextField source="amount" />
          <TextField source="settledAmount" />
          <TextField source="accountInfo.accountNumber" label="Account No" />
          <TextField source="accountInfo.ifscCode" label="IFSC Code" />
          <TextField source="accountInfo.upiId" label="Upi" />

          <TextField source="accountVerificationCharges" />
          <FunctionField
            label="Status"
            render={(record: any) => {
              if (record.status === "pending") {
                return <ChipField source="status" color="warning" />;
              } else if (record.status === "success") {
                return <ChipField source="status" color="success" />;
              } else {
                return <ChipField source="status" color="error" />;
              }
            }}
          />
          <DateField source="createdAt" showTime label="Created at" />
          <DateField source="updatedAt" showTime label="Updated at" />

          <FunctionField
            label="Actions"
            render={(record: any) =>
              record.status === "manualReview" ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "220px",
                    position: "sticky",
                    right: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                >
                  <Button
                    onClick={() => handleVerifyClick(record.orderId)}
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ minWidth: "60px", fontSize: "0.75rem" }}
                  >
                    Verify
                  </Button>
                  <Button
                    onClick={() => handleActionClick(record.orderId, "approve")}
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ minWidth: "60px", fontSize: "0.75rem" }}
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={() => handleActionClick(record.orderId, "reject")}
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ minWidth: "60px", fontSize: "0.75rem" }}
                  >
                    Reject
                  </Button>
                </Box>
              ) : (
                "N/A"
              )
            }
          />
          <FunctionField
            label="KYC Name"
            render={(record: any) =>
              record.status === "manualReview"
                ? verificationData[record.orderId]?.kycName || ""
                : ""
            }
          />
          <FunctionField
            label="Account Holder Name"
            render={(record: any) =>
              record.status === "manualReview"
                ? verificationData[record.orderId]?.accountHolderName || ""
                : ""
            }
          />
            <FunctionField
            label="Reason"
            render={(record: any) =>
              record.status === "manualReview"
                ? verificationData[record.orderId]?.reason || ""
                : ""
            }
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Withdrawals;
