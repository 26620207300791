import {
  AppBar,
  Logout,
  UserMenu,
  usePermissions,
  useUserMenu,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Theme,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import ToggleOnRoundedIcon from "@mui/icons-material/ToggleOnRounded";
import MoodIcon from "@mui/icons-material/Mood";
const ConfigurationMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();

  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/configuration"
      onClick={onClose}
    >
      <ListItemIcon>
        <ToggleOnRoundedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Dark Mode</ListItemText>
    </MenuItem>
  );
});

const AdminMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const { permissions } = usePermissions();
  const hasGameConfigPermission = !permissions.includes("developer");
  if (!hasGameConfigPermission) {
    return null;
  }

  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/profile"
      onClick={onClose}
    >
      <ListItemIcon>
        <MoodIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Profile" />
    </MenuItem>
  );
});

export const GameConfigMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const { permissions } = usePermissions();
  const hasGameConfigPermission = permissions.includes("admin") || permissions.includes("developer");
  if (!hasGameConfigPermission) {
    return null;
  }

  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/api/game-config"
      onClick={onClose}
    >
      <ListItemIcon>
        <SettingsIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Configuration" />
    </MenuItem>
  );
});

const CustomUserMenu = () => (
  <UserMenu>
    <AdminMenu />
    <ConfigurationMenu />
    <GameConfigMenu />
    <Logout />
  </UserMenu>
);

const CustomAppBar = () => {
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("sm")
  );
  return (
    <AppBar color="secondary" elevation={1} userMenu={<CustomUserMenu />}>
      Empire Games Admin Panel
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

export default CustomAppBar;
