import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import {
  get,
  isEqual,
  isUndefined,
  omit,
  omitBy,
  sumBy,
  toNumber,
  toString,
} from "lodash";
import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import { callRestApi } from "../../utils/callRestApi";
import { TournamentDataType, WinningPrizesType } from "../tournaments/tournamentTypes";
import { MegaTournamentDataType } from "./megaTournamentTypes";


const AddTournaments = () => {
  const [winningPriceRef, setWinningPriceRef] = useState<WinningPrizesType[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [totalCreditAmountFlag, setTotalCreditAmountFlag] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    control,
    getValues,
    setValue,
    setError,
    clearErrors
  } = useForm<MegaTournamentDataType>({
    defaultValues: {
      winningPrizes: [
        {
          minRank: 1,
          maxRank: 1,
          amount: "1",
          percentage: 0,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "winningPrizes",
    control,
  });

  const watchingWinningPrice = useWatch({ control, name: "winningPrizes" });
  const watchingJoinFee = useWatch({ control, name: "joinFee" });
  const watchMaxTotalEntries = useWatch({ control, name: "maxTotalEntries" });

  // This will set the updated value to state if there is any changes in winningprize inputs
  useEffect(() => {
    if (!isEqual(winningPriceRef, watchingWinningPrice)) {
      setWinningPriceRef(watchingWinningPrice);
    }
    // eslint-disable-next-line
  }, [watchingWinningPrice]);

  // This will update and set the calculated values to readonly form fields
  useEffect(() => {
    updateWinningPriceFields();
    // eslint-disable-next-line
  }, [winningPriceRef, watchingJoinFee, watchMaxTotalEntries]);

  useEffect(() => {
    const joinFee = parseFloat(watchingJoinFee || '0');
    // const maxTotalEntries = parseFloat(watchMaxTotalEntries || '0');
    setValue('totalJoinFees', joinFee * watchMaxTotalEntries || 0);
  }, [watchingJoinFee, watchMaxTotalEntries, setValue]);


  const updateWinningPriceFields = () => {
    const formValues = getValues() as MegaTournamentDataType;
    const { winningPrizes } = formValues;
    let totalCreditAmount = 0;


    winningPrizes.forEach((winningPrice: WinningPrizesType, index: number) => {
      //Update total credit amount
      const amount = winningPrice?.totalWinnerAmount ?? 0;
      totalCreditAmount += amount;

      // Update Percentage
      setValue(
        `winningPrizes.${index}.percentage`,
        Number.isNaN(
          (parseFloat(winningPrice.amount) /
            (parseFloat(formValues.joinFee) * formValues.maxTotalEntries)) *
          100
        )
          ? 0
          : (parseFloat(winningPrice.amount) /
            (parseFloat(formValues.joinFee) * formValues.maxTotalEntries)) *
          100
      );
      // Update Total Winner Percentage
      setValue(
        `winningPrizes.${index}.totalWinnerPercentage`,
        Number.isNaN(
          (winningPrice.maxRank - winningPrice.minRank + 1) *
          winningPrice.percentage
        )
          ? 0
          : (winningPrice.maxRank - winningPrice.minRank + 1) *
          winningPrice.percentage
      );
      // Update Total Winner Amount
      setValue(
        `winningPrizes.${index}.totalWinnerAmount`,
        (winningPrice.maxRank - winningPrice.minRank + 1) *
        parseFloat(winningPrice.amount)
      );
      // Update total winner number
      setValue(
        `winningPrizes.${index}.totalWinnersNumber`,
        winningPrice.maxRank - winningPrice.minRank + 1
      );
    });
    const joinFee = parseFloat(formValues.joinFee || '2');
    const totalJoinFees = joinFee * watchMaxTotalEntries;
    
    setValue('totalCreditAmount', totalCreditAmount);

    if (totalCreditAmount > totalJoinFees) {
      setError('totalCreditAmount', {
        type: 'manual',
        message: 'Total credit amount exceeds maximum winning pool',
      });
      setTotalCreditAmountFlag(true);
      return;
    } else {
      clearErrors('totalCreditAmount');
      setTotalCreditAmountFlag(false);
    }

  };

  const onSubmit = async (data: MegaTournamentDataType) => {

    data.endAt = dayjs(data.endAt).toISOString();
    data.extensionTime = toNumber(data.extensionTime);
    data.maxEntriesPerUser = toNumber(data.maxEntriesPerUser);
    data.totalMoves = toNumber(data.totalMoves);
    data.maxTotalEntries = toNumber(data.maxTotalEntries);
    data.maxExtensionLimit = toNumber(data.maxExtensionLimit);
    data.isRepeatable = JSON.parse(toString(data.isRepeatable));
    data.useSamePawnPositions = JSON.parse(toString(data.useSamePawnPositions))
    data.winningPrizes.forEach((winningPrizeData: WinningPrizesType) => {
      winningPrizeData.minRank = toNumber(winningPrizeData.minRank);
      winningPrizeData.maxRank = toNumber(winningPrizeData.maxRank);
    });

    const payload = omit(omitBy(data, (value) => value === "" || isUndefined(value)), ['totalCreditAmount', 'totalJoinFees']);
    const payloadWithOmittedFields = JSON.parse(JSON.stringify(payload));

    payloadWithOmittedFields.winningPrizes = payloadWithOmittedFields.winningPrizes.map((prize: any) => {
      const { totalWinnerAmount, totalWinnerPercentage, totalWinnersNumber, ...rest } = prize;
      return rest;
    });

    try {
      setLoading(true);
      const tournamentResponse = await callRestApi(
        "/ludo/mega-tournament",
        "POST",
        { ...payloadWithOmittedFields }
      );
      toast(
        get(tournamentResponse, "data")
          ? "Tournament Created Successfully with id " +
          get(tournamentResponse, "data")
          : "Something Went Wrong",
        {
          type: get(tournamentResponse, "data")
            ? "success"
            : "error",
        }
      );
      reset();
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card style={{ padding: "25px" }}>
      <h4>Create Mega Tournament</h4>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="name">Enter Tournament Name</InputLabel>
              <TextField
                {...register("name", { required: "Name is required" })}
                id="name"
                name="name"
                placeholder="Name"
                fullWidth
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="isRepeatable">
                Repeatable tournament?
              </InputLabel>
              <FormControl error={!!errors.isRepeatable} component="fieldset">
                <Controller
                  rules={{ required: "Please select the option" }}
                  control={control}
                  name="isRepeatable"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    );
                  }}
                />
                {errors.isRepeatable && (
                  <span
                    style={{
                      fontSize: "0.75rem",
                      color: "#f44336",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.isRepeatable.message}
                  </span>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="alias">Alias Name</InputLabel>
              <TextField
                {...register("alias", {
                  required: {
                    value:
                      String(watch("isRepeatable")) === "true",
                    message:
                      "Alias is Required If Tournament is Repeatable or Automatic",
                  },
                })}
                id="alias"
                name="alias"
                disabled={
                  String(watch("isRepeatable")) !== "true"
                }
                placeholder="Alias Name"
                fullWidth
                error={!!errors.alias}
                helperText={errors.alias && errors.alias.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="maxTotalEntries">
                Max Entries
              </InputLabel>
              <TextField
                {...register("maxTotalEntries", {
                  required: "Max Entries is required",
                })}
                id="maxTotalEntries"
                name="maxTotalEntries"
                placeholder="Enter Max Total Entries"
                type="number"
                fullWidth
                error={!!errors.maxTotalEntries}
                helperText={errors.maxTotalEntries && errors.maxTotalEntries.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="maxEntriesPerUser">
                Max Entries Per User
              </InputLabel>
              <TextField
                {...register("maxEntriesPerUser", {
                  required: "Max Entries Per User is required",
                })}
                id="maxEntriesPerUser"
                name="maxEntriesPerUser"
                placeholder="Enter Max Entries per User"
                type="number"
                fullWidth
                error={!!errors.maxEntriesPerUser}
                helperText={errors.maxEntriesPerUser && errors.maxEntriesPerUser.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="joinFee">Join Fees</InputLabel>
              <TextField
                {...register("joinFee", {
                  required: "Join Fee is required",
                  pattern: {
                    message: "Join Amount Should be Only Number or Decimal",
                    value: /^[0-9]\d{0,9}(\.\d{1,3})?%?$/gm,
                  },
                })}
                id="joinFee"
                name="joinFee"
                placeholder="Enter Join Fees"
                fullWidth
                error={!!errors.joinFee}
                helperText={errors.joinFee && errors.joinFee.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="totalMoves">Total Moves</InputLabel>
              <TextField
                {...register("totalMoves", {
                  required: "Total Moves is required",
                  pattern: {
                    message: "Total Moves Should be Only Number or Decimal",
                    value: /^[0-9]\d{0,9}(\.\d{1,3})?%?$/gm,
                  },
                })}
                id="totalMoves"
                name="totalMoves"
                placeholder="Enter total Moves"
                fullWidth
                error={!!errors.totalMoves}
                helperText={errors.totalMoves && errors.totalMoves.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="maxExtensionLimit">
                Max Extension Limit
              </InputLabel>
              <TextField
                {...register("maxExtensionLimit", {
                  required: "Max Extension Limit is required",
                })}
                id="maxExtensionLimit"
                name="maxExtensionLimit"
                placeholder="Enter Max Extension Limit"
                type="number"
                fullWidth
                error={!!errors.maxExtensionLimit}
                helperText={errors.maxExtensionLimit && errors.maxExtensionLimit.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="extensionTime">
                Extension Time
              </InputLabel>
              <TextField
                {...register("extensionTime", {
                  required: "Extension Time is required",
                })}
                id="extensionTime"
                name="extensionTime"
                placeholder="Enter Extension Time"
                type="number"
                fullWidth
                error={!!errors.extensionTime}
                helperText={errors.extensionTime && errors.extensionTime.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="endAt">Tournament End Time</InputLabel>
              <TextField
                {...register("endAt", {
                  required: "Tournament End Time is required",
                  validate: (value) =>
                    dayjs().format("YYYY-MM-DDTHH:mm") <
                    dayjs(value).format("YYYY-MM-DDTHH:mm") ||
                    "Tournament End Time cannot be before current time",
                })}
                id="endAt"
                name="endAt"
                type="datetime-local"
                fullWidth
                error={!!errors.endAt}
                helperText={errors.endAt && errors.endAt.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="joinFee">Total Join Fees</InputLabel>
              <TextField
                {...register("totalJoinFees", {
                })}
                id="totalJoinFees"
                name="totalJoinFees"
                placeholder="Enter Join Fees"
                InputProps={{ readOnly: true }}
                fullWidth
                error={!!errors.totalJoinFees}
                helperText={errors.totalJoinFees && errors.totalJoinFees.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="joinFee">Total Credit Amount</InputLabel>
              <TextField
                {...register("totalCreditAmount", {
                })}
                id="totalCreditAmount"
                name="totalCreditAmount"
                placeholder="Enter Total Credit Amount"
                InputProps={{ readOnly: true }}
                fullWidth
                error={!!errors.totalCreditAmount}
                helperText={errors.totalCreditAmount && errors.totalCreditAmount.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="useSamePawnPositions">
                Use same pawn positions?
              </InputLabel>
              <FormControl error={!!errors.useSamePawnPositions} component="fieldset">
                <Controller
                  rules={{ required: "Please select the option" }}
                  control={control}
                  name="useSamePawnPositions"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    );
                  }}
                />
                {errors.useSamePawnPositions && (
                  <span
                    style={{
                      fontSize: "0.75rem",
                      color: "#f44336",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.useSamePawnPositions.message}
                  </span>
                )}
              </FormControl>
            </Grid>

          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Box display="flex" justifyContent="end">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  append({
                    minRank: 1,
                    maxRank: 1,
                    amount: "1",
                    percentage: 0.1,
                  })
                }
              >
                Add Winning Detail
              </Button>
            </Box>
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            {fields.map((field, index) => {
              return (
                <Grid
                  item
                  key={field.id}
                  xs={12}
                  md={2}
                  style={{ marginBottom: "5px" }}
                >
                  <Card style={{ padding: "10px" }}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" justifyContent="space-between">
                        <h5>Winning Prize</h5>
                        {index > 0 && (
                          <HighlightOff
                            onClick={() => remove(index)}
                            color="error"
                          />
                        )}
                      </Box>

                      <TextField
                        label="Minimum Rank"
                        {...register(
                          `winningPrizes.${index}.minRank` as const,
                          {
                            required: "Minimum Rank is required field",
                            pattern: /^\d+$/,
                          }
                        )}
                        error={
                          get(errors, `winningPrizes.${index}.minRank`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.minRank.message`,
                          ""
                        )}
                        type="number"
                      />
                      <TextField
                        label="Maximum Rank"
                        {...register(
                          `winningPrizes.${index}.maxRank` as const,
                          {
                            required: "Maximum Rank is required field",
                            pattern: /^\d+$/,
                          }
                        )}
                        error={
                          get(errors, `winningPrizes.${index}.maxRank`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.maxRank.message`,
                          ""
                        )}
                        type="number"
                      />
                      <TextField
                        label="Amount"
                        {...register(`winningPrizes.${index}.amount`, {
                          required: "Amount is required field",
                        })}
                        error={
                          get(errors, `winningPrizes.${index}.amount`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.amount.message`,
                          ""
                        )}
                        type="text"
                      />
                      <TextField
                        label="Percentage"
                        {...register(`winningPrizes.${index}.percentage`, {
                          required: "Percentage is required field",
                        })}
                        error={
                          get(errors, `winningPrizes.${index}.percentage`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.percentage.message`,
                          ""
                        )}
                        type="text"
                        inputProps={{
                          step: "0.1",
                        }}
                      />

                      <TextField
                        label="Total Amount"
                        {...register(
                          `winningPrizes.${index}.totalWinnerAmount`
                        )}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Total Percentage"
                        {...register(
                          `winningPrizes.${index}.totalWinnerPercentage`
                        )}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Total Winners"
                        {...register(
                          `winningPrizes.${index}.totalWinnersNumber`
                        )}
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <br />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box display="flex" justifyContent="space-between">
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={() => reset()}
                style={{ maxWidth: "50%" }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                disabled={totalCreditAmountFlag}
                style={{ maxWidth: "50%" }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </form>
      </Box>
    </Card>
  );
};
export default AddTournaments;