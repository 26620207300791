import {
  List,
  TextField,
  DateField,
  ChipField,
  DatagridConfigurable,
  Filter,
  SearchInput,
  SelectArrayInput,
  FilterForm,
  usePermissions,
  SelectColumnsButton,
  FilterButton,
  TopToolbar,
  ExportButton,
  DateTimeInput,
  NumberField,
} from "react-admin";
import { Stack } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";

const ListToolbar = () => <Stack direction="row"></Stack>;

const Referraltransactions = (props: any) => {
  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Referral Code"
        source="referral.code"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Referral History</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="username" to="_id" />
          <TextField source="referral.code" label="Code " />
          <TextField source="referral.count" label="Referred Users  " />
          <NumberField
            source="referral.earning"
            label="Earn Amount"
            options={{
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
           <DateField source="updatedAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Referraltransactions;
