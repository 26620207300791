import { Chip, Typography, useTheme } from "@mui/material";
import { get, toLower } from "lodash";

const StatusField = (props: any) => {
  const {
    value,
    isBadge = false,
    variant,
    icon,
    color,
    source,
    booleanLabel,
  } = props;

  const theme = useTheme();

  const colorSource: object = {
    kyc: {
      pending: "warning",
      initiated: "info",
      completed: "success",
      rejected: "error",
      failed: "secondary",
    },
    boolean: {
      true: "success",
      false: "error",
    },
  };

  const getColor = (value: string, source: string) => {
    return get(colorSource, `${source}.${value}`, "secondary");
  };

  return isBadge ? (
    <Chip
      label={booleanLabel ? booleanLabel : value}
      variant={variant}
      icon={icon}
      color={color ? color : getColor(toLower(value), source)}
    />
  ) : (
    <Typography
      color={get(theme, `palette.${getColor(toLower(value), source)}.main`)}
      variant="body2"
    >
      {booleanLabel ? booleanLabel : value}
    </Typography>
  );
};

export default StatusField;
