import React, { useState } from "react";
import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
  Button as RaButton,
} from "react-admin";
import {
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
} from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";
import { callRestApi } from "../utils/callRestApi";

const Converts = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "success", name: "Success" },
        { id: "failed", name: "Failed" },
        { id: "pending", name: "Pending" },
        { id: "refund", name: "Refund" },
      ]}
    />,
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Amount"
        source="amount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Convert</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="orderId" label="Order ID" />

          <FunctionField
            label="Amount"
            render={(record: any) => `₹${record.amount}`}
          />

          <FunctionField
            label="Settled Amount"
            render={(record: any) => `₹${record.settledAmount}`}
          />

          <FunctionField
            label="Status"
            render={(record: any) => {
              if (record.status === "pending") {
                return <ChipField source="status" color="warning" />;
              } else if (record.status === "success") {
                return <ChipField source="status" color="success" />;
              } else {
                return <ChipField source="status" color="error" />;
              }
            }}
          />
          <DateField source="createdAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Converts;
