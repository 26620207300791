import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const MegaTournamentWinningsPrizeModal = (props: any) => {
  const { open, close, data = [] } = props;
  const { winningPrizes = [], name } = data;

  const winningPrizesWithId = winningPrizes.map((prize: any, index: number) => ({
    ...prize,
    id: index + 1, 
  }));

  const winningPrizesColumns = [
    { field: 'minRank', headerName: 'Min Rank', flex: 1 },
    { field: 'maxRank', headerName: 'Max Rank', flex: 1 },
    { field: 'amount', headerName: 'Amount', flex: 1 },
    { field: 'percentage', headerName: 'Percentage', flex: 1 },
  ];

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={() => close(false)}>
      <DialogTitle>Winning Prizes for Tournament - {name}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 400, 
          }}
        >
          <DataGrid
            columns={winningPrizesColumns}
            rows={winningPrizesWithId}
            getRowId={(row) => row.id}
            pageSizeOptions={[5, 10, 20]} 
            autoHeight 
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MegaTournamentWinningsPrizeModal;
