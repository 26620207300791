import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { get } from "lodash";
import UserInfoRow from "../../components/UserInfoRow";

const GameDetailsModal = (props: any) => {
    const { open, close, data = [], } = props;
    return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
            <DialogTitle>Game Details</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <UserInfoRow
                        label="Earned Money"
                        value={get(data, "earnedMoney", 0)}
                    />
                    <UserInfoRow
                        label="Won Matches"
                        value={get(data, "winMatches", 0)}
                    />
                    <UserInfoRow
                        label="Lost Matches"
                        value={get(data, "lossMatches", 0)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default GameDetailsModal;
