import config from "../config";
import dayjs from "dayjs";

export const getItemWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key);

  // If the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);

  // Compare the expiry time of the item with the current time
  if (
    dayjs().isAfter(dayjs(item.time).add(config.SESSION_EXPIRE_TIME, "minutes"))
  ) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }

  return item;
};
