import React, { useEffect, useState } from "react";
import querystring from "query-string";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { callRestApi } from "../../utils/callRestApi";
import { AxiosResponse } from "axios";
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
  InputLabel,
  CircularProgress,
  Box,
} from "@mui/material";
import dayjs from "dayjs";

const GraphApp: React.FC = () => {
  const [graphData, setGraphData] = useState<any[]>([]);
  const [groupBy, setGroupBy] = useState<string>("week");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [, setShowMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (startDate && endDate) {
      setError(null);
      setShowMessage(false);
      fetchData();
    } else {
      setShowMessage(true);
    }
    // eslint-disable-next-line
  }, [startDate, endDate, groupBy]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const query = {
        filter: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
        }),
        groupBy,
      };

      const { data } = (await callRestApi(
        `/users-graph`,
        "GET",
        {},
        querystring.stringify(query)
      )) as AxiosResponse;

      if (data?.data) {
        const formattedData = data?.data.map(
          (item: { startDate: string; endDate: string; count: number }) => ({
            ...item,
            startDate: formatDate(item.startDate.split("T")[0]),
            endDate: formatDate(item.endDate.split("T")[0]),
          })
        );
        setGraphData(formattedData);
      } else {
        throw new Error("No data returned from API");
      }
    } catch (error) {
      setError("Error fetching data. Please try again later.");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date: any) => {
    return dayjs(date).format("DD-MMM-YY");
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          style={{
            fontFamily: "'Playfair Display'",
            fontWeight: "bold",
          }}
        >
          Users Count Bar Graph
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={10} style={{ padding: "16px" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={4}>
              <InputLabel id="group-by-label">Group By:</InputLabel>
              <Select
                labelId="group-by-label"
                id="group-by"
                value={groupBy}
                onChange={(e) => setGroupBy(e.target.value as string)}
                fullWidth
              >
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="start-date"
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="end-date"
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ height: "80vh", padding: "16px" }}>
          {(!startDate || !endDate) && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              color="black"
            >
              <Typography
                variant="h4"
                component="div"
                style={{ fontFamily: "Playfair Display" }}
              >
                Please select Start Date and End Date.
              </Typography>
            </Box>
          )}
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          )}
          {error && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              color="red"
            >
              <Typography variant="h4" component="div">
                {error}
              </Typography>
            </Box>
          )}
          {graphData.length > 0 && !loading && !error && (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={graphData}>
                <CartesianGrid stroke="#ccc" />
                <XAxis
                  dataKey={({ startDate, endDate }) =>
                    `${formatDate(startDate)} to ${formatDate(endDate)}`
                  }
                  angle={-54}
                  textAnchor="end"
                  interval={0}
                  height={160}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                {graphData.length > 0 && (
                  <Bar dataKey="count">
                    {graphData.map((item, index) => (
                      <Cell
                        key={`bar-${index}`}
                        fill={
                          [
                            "rgba(255, 99, 132, 0.6)",
                            "rgba(255, 159, 64, 0.6)",
                            "rgba(255, 205, 86, 0.6)",
                            "rgba(75, 192, 192, 0.6)",
                            "rgba(54, 162, 235, 0.6)",
                            "rgba(153, 102, 255, 0.6)",
                            "rgba(201, 203, 207, 0.6)",
                          ][index % 7]
                        } 
                      stroke={
                        [
                          'rgb(255, 99, 132)',
                          'rgb(255, 159, 64)',
                          'rgb(255, 205, 86)',
                          'rgb(75, 192, 192)',
                          'rgb(54, 162, 235)',
                          'rgb(153, 102, 255)',
                          'rgb(201, 203, 207)',
                        ][index % 7]
                      } 
                      strokeWidth={2.4} 
                    />
                    ))}
                  </Bar>
                )}
              </BarChart>
            </ResponsiveContainer>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GraphApp;
