import React from "react";
import Big from "big.js";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const ReRoundCard = ({ data }: any) => {
  const { roundId, updatedAt, userInfo,score, wildCard, winner, roundStartedAt } = data;

  const PlayerRow = ({ data }: any) => {
    const { username, userId, score,handCards, status, winLossAmount, outcome } = data;

    const isWinner = winner === userId;

    const formatAmount = (amount: Big.BigSource) => {
      const formattedAmount = new Big(amount).toFixed(2);
      return `₹${formattedAmount}`;
    };

    const getOutcomeColor = (outcome: string) => {
      return outcome === "won" ? "green" : "red";
    };

  
    const formattedHandCards = handCards
      ?.map((group: any) => (
        <div key={group.cards.join(", ")}>
          <span>[{group.cards.join(", ")}]</span>{" "}
          <span
            style={{
              color: group.valid ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            (Valid: {group.valid ? "true" : "false"})
          </span>
        </div>
      )) || <span>NA</span>;

    return (
      <TableRow className={isWinner ? "winner-row" : ""}>
        <TableCell component="th" scope="row">
          <Link
            style={{ textDecoration: "none" }}
            to={`/api/users/${userId}/show`}
          >
            {username}
          </Link>
        </TableCell>
        <TableCell>
          {winLossAmount && (
            <span
              style={{ color: parseFloat(winLossAmount) > 0 ? "green" : "red" }}
            >
              {formatAmount(winLossAmount)}
            </span>
          )}
        </TableCell>
        <TableCell>{score}</TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>{formattedHandCards}</TableCell>
        <TableCell style={{ color: getOutcomeColor(outcome) }}>
          {outcome}
        </TableCell>
      </TableRow>
    );
  };

  const totalPlayers = userInfo.length;

  return (
    <Card>
      <CardHeader
        title={
          <React.Fragment>
            <b>Round Id:</b> {roundId} | <b>Wild Card:</b> {wildCard} |{" "}
            <b>Total Players:</b> {totalPlayers}
          </React.Fragment>
        }
        subheader={
          <React.Fragment>
            <b>Round Started At:</b>{" "}
            {new Date(roundStartedAt).toLocaleString()} |{" "}
            <b>Updated At:</b> {new Date(updatedAt).toLocaleString()}
          </React.Fragment>
        }
      />
      <CardContent>
        <div style={{ marginBottom: "10px" }}>
          <h4>
            <b>Winner:</b>{" "}
            {userInfo
              .filter((user: any) => user.outcome === "won")
              .map((winner: any) => (
                <React.Fragment key={winner.userId}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/api/users/${winner.userId}/show`}
                  >
                    {winner.username}
                  </Link>
                  {", "}
                </React.Fragment>
              ))}
          </h4>
        </div>
        <div style={{ overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Username</b>
                </TableCell>
                <TableCell>
                  <b>Win/Loss Amount (₹)</b>
                </TableCell>
                <TableCell>
                  <b>Score</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
                <TableCell>
                  <b>Hand Cards</b>
                </TableCell>
                <TableCell>
                  <b>Outcome</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userInfo.map((user: any) => (
                <PlayerRow key={user.userId} data={user} />
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default ReRoundCard;
