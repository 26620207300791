import Joi from "joi";

export enum Roles {
  player = "player",
  admin = "admin",
  support = "support",
  finance = "finance",
  developer = "developer",
}

export const roleValidationSchema = Joi.object({
  roles: Joi.array()
    .items(Joi.string().valid(...Object.values(Roles)))
    .required()
    .custom((value, helpers) => {
      if (value.length === 0) {
        return helpers.error("any.required");
      }
      return value;
    })
    .messages({
      "any.required": "Role must be required property",
      "array.includesOne": "Invalid role",
      "any.only": "Role must be one of [player, admin, support, finance]",
    }),
});