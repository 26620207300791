import React, { useEffect, useMemo, useState } from "react";
import { useLogin, useNotify } from "react-admin";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { Slide } from "@mui/material";
import config from "../config";

export default function LoginPage() {
  const [mobileNo, setMobileNo] = useState("");
  const [otp, setOtp] = useState("");
  const [inputOtp, setInputOtp] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [mobileNoIsValid, setMobileNoIsValid] = useState(false);
  const validateMobileNumber = (number: any) => {
    const isValid = /^\d{10}$/.test(number);
    setMobileNoIsValid(isValid);
  };

  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    let intervalId: any;

    if (inputOtp) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [inputOtp, countdown]);

  const handleSendOTP = async () => {
    try {
      const result = await axios.post(
        `/auth/init?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          mobileNo,
          build: {
            "appVersion":"0.0.1",
            "appCode":"Editor",
            "isPlayStoreBuild":false,
            "isGlobalBuild":false
          }
        }
      );

      if (result.status === 201) {
        if (new Date(result.data.expiresAt) < new Date()) {
          notify("OTP is expired");
        } else {
          notify(`OTP is sent to (${mobileNo})`);
          setInputOtp(true);
          setCountdown(60);
        }
      }
    } catch (error) {
      notify("Error Sending OTP! Please try again after some time.");
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!inputOtp) {
      axios
        .post(`/auth/init?key=${config.MAINTENANCE_BYPASS_KEY}`, {
          mobileNo,
          build: {
            "appVersion":"0.0.1",
            "appCode":"Editor",
            "isPlayStoreBuild":false,
            "isGlobalBuild":false
          }
        })
        .then((result) => {
          if (result.status === 201) {
            if (new Date(result.data.expiresAt) < new Date()) {
              notify("OTP is expired");
              validateMobileNumber(mobileNo);
            } else {
              notify(`OTP is sent to (${mobileNo})`);
              setInputOtp(true);
              setCountdown(60);
            }
          }
        })
        .catch(() =>
          notify("Error Sending OTP! Please try again after some time.")
        );
    } else {
      login({ mobileNo, otp }).catch((err) => {
        if (err.response?.status === 401) notify("OTP is invalid");
        else notify(err.response?.data?.message || err.message);
      });
    }
  };

  const mobileNumComp = (
    <Slide direction="up" in>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Input Phone Number
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            style={{ width: "500px" }}
          >
            <TextField
              margin="normal"
              required
              value={mobileNo}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                setMobileNo(e.target.value);
                validateMobileNumber(e.target.value);
              }}
              fullWidth
              name="otp"
              label="Mobile Number"
              id="otp"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!mobileNoIsValid}
            >
              Send OTP
            </Button>
          </Box>
        </Box>
      </Container>
    </Slide>
  );

  const otpComp = (
    <Slide direction="up" in>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verify OTP
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            style={{ width: "500px" }}
          >
            <TextField
              margin="normal"
              required
              value={otp}
              inputProps={{ maxLength: 6 }}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              name="otp"
              label="OTP Code"
              id="otp"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Button
              variant="text"
              onClick={() => setInputOtp(false)}
              sx={{ mt: 6, mb: 5 }}
              style={{
                marginTop: "-7px",
                marginLeft: "20rem"
              }}
            >
              Use another phone
            </Button>
          </Box>
        </Box>
      </Container>
    </Slide>
  );
  const signInComp = useMemo(
    () => (
      <>
        {inputOtp && (
          <Box
            sx={{
              position: "relative",
            }}
          >
            {otpComp}
            {countdown === 0 && (
              <Button
                onClick={handleSendOTP}
                style={{
                  position: "absolute",
                  top: "16rem",
                  left: "45.8%",
                  transform: "translate(-110%, -50%)",
                }}
              >
                Send OTP Again
              </Button>
            )}
            {countdown > 0 && (
              <Typography
                color="textSecondary"
                align="center"
                style={{
                  marginTop: "-70px",
                  marginLeft: "-19rem"
                }}
              >
                Resend OTP in {countdown} seconds
              </Typography>
            )}
          </Box>
        )}
        {!inputOtp && mobileNumComp}
      </>
    ),
    // eslint-disable-next-line
    [inputOtp, otp, mobileNo, countdown]
  );

  return <>{signInComp}</>;
}
