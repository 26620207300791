import React, { useState, useEffect } from "react";
import { CircularProgress, Box } from "@mui/material";
import { DateRangePicker } from "rsuite";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";
import axios from "axios";
import { CSVLink } from "react-csv";
import { DataGrid } from "@mui/x-data-grid"; // Import DataGrid from Material-UI

const breakpoints = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
};

const getDateArray = (date1: Date, date2: Date) => {
  const dateArray = [];
  let currentDate = new Date(date1);
  while (currentDate <= date2) {
    dateArray.push(formatDate(currentDate));
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  return dateArray;
};

const DailyReport = () => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [stats, setStats] = useState({
    totalSuccessDepositAmount: 0,
    totalSuccessWithdrawalAmount: 0,
    totalGames: 0,
    totalGameRounds: 0,
    totalCompletedKyc: 0,
    totalSuccessfulReferredUsers: 0,
  });
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - 30);

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startDate,
    currentDate,
  ]);

  const dynamicHeight = "80vh";

  const [
    dayWisetotalSuccessDepositAmount,
    setDayWisetotalSuccessDepositAmount,
  ] = useState([]);
  const [
    dayWisetotalSuccessWithdrawalAmount,
    setdayWisetotalSuccessWithdrawalAmount,
  ] = useState([]);
  const [dayWiseTotalGames, setdayWiseTotalGames] = useState([]);
  const [dayWiseTotalGameRounds, setdayWiseTotalGameRounds] = useState([]);
  const [dayWiseTotalUserCount, setdayWiseTotalUserCount] = useState([]);
  const [dayWiseTotalCompletedKyc, setdayWiseTotalCompletedKyc] = useState([]);
  const [
    dayWiseTotalSuccessfulReferredUsers,
    setdayWiseTotalSuccessfulReferredUsers,
  ] = useState([]);
  // eslint-disable-next-line
  const [csvData, setCSVData] = useState([]);

  const handleDateSelect = (value: any) => {
    if (value) {
      setDateRange(value);
    } else {
      setDateRange([new Date(), new Date()]);
    }
  };

  const dateArray = getDateArray(dateRange[0], dateRange[1]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }
      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();
      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();
      const filter = {
        startDate,
        endDate,
      };

      const apiEndpoints = [
        `${config.REST_ENDPOINT}/payment-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        `${config.REST_ENDPOINT}/gameplay-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        `${config.REST_ENDPOINT}/users-graph?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        `${config.REST_ENDPOINT}/kyc-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        `${config.REST_ENDPOINT}/referrals/stats?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
      ];

      const apiRequests = apiEndpoints.map((endpoint) =>
        axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
      );

      const responses = await Promise.all(apiRequests);
      // eslint-disable-next-line
      const combinedData = {
        dayWisetotalSuccessDepositAmount:
          responses[0].data.dayWisetotalSuccessDepositAmount,
        dayWisetotalSuccessWithdrawalAmount:
          responses[0].data.dayWisetotalSuccessWithdrawalAmount,
        dayWiseTotalGames: responses[1].data.dayWiseTotalGames,
        dayWiseTotalGameRounds: responses[1].data.dayWiseTotalGameRounds,
        dayWiseTotalUserCount:
          responses[2].data.Totalcount.dayWiseTotalUserCount,
        dayWiseTotalCompletedKyc: responses[3].data.dayWiseTotalCompletedKyc,
        dayWiseTotalSuccessfulReferredUsers:
          responses[4].data.dayWiseTotalSuccessfulReferredUsers,
      };

      const dayWisetotalSuccessDepositAmount =
        responses[0].data.dayWisetotalSuccessDepositAmount;
      const dayWisetotalSuccessWithdrawalAmount =
        responses[0].data.dayWisetotalSuccessWithdrawalAmount;

      const dayWiseTotalGames = responses[1].data.dayWiseTotalGames;
      const dayWiseTotalGameRounds = responses[1].data.dayWiseTotalGameRounds;
      const dayWiseTotalUserCount =
        responses[2].data.Totalcount.dayWiseTotalUserCount;
      const dayWiseTotalCompletedKyc =
        responses[3].data.dayWiseTotalCompletedKyc;
      const dayWiseTotalSuccessfulReferredUsers =
        responses[4].data.dayWiseTotalSuccessfulReferredUsers;

      setDayWisetotalSuccessDepositAmount(dayWisetotalSuccessDepositAmount);
      setdayWisetotalSuccessWithdrawalAmount(
        dayWisetotalSuccessWithdrawalAmount
      );
      setdayWiseTotalGames(dayWiseTotalGames);
      setdayWiseTotalGameRounds(dayWiseTotalGameRounds);
      setdayWiseTotalUserCount(dayWiseTotalUserCount);
      setdayWiseTotalCompletedKyc(dayWiseTotalCompletedKyc);
      setdayWiseTotalSuccessfulReferredUsers(
        dayWiseTotalSuccessfulReferredUsers
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getDayWisetotalSuccessDepositAmount = (date: string) => {
    const result: any = dayWisetotalSuccessDepositAmount.find(
      (item: any) => item.date === date
    );
    return result ? result.totalSuccessDepositAmount : 0;
  };

  const getDayWisetotalSuccessWithdrawalAmount = (date: string) => {
    const result: any = dayWisetotalSuccessWithdrawalAmount.find(
      (item: any) => item.date === date
    );
    return result ? result.totalSuccessWithdrawalAmount : 0;
  };

  const getDayWiseTotalGames = (date: string) => {
    const result: any = dayWiseTotalGames.find(
      (item: any) => item._id === date
    );
    return result ? result.count : 0;
  };

  const getDayWiseTotalGameRounds = (date: string) => {
    const result: any = dayWiseTotalGameRounds.find(
      (item: any) => item._id === date
    );
    return result ? result.count : 0;
  };

  const getDayWiseTotalUserCount = (date: string) => {
    const result: any = dayWiseTotalUserCount.find(
      (item: any) => item.date === date
    );
    return result ? result.dayTotalUserCount : 0;
  };

  const getDayWiseTotalCompletedKyc = (date: string) => {
    const result: any = dayWiseTotalCompletedKyc.find(
      (item: any) => item.date === date
    );
    return result ? result.totalCompletedKyc : 0;
  };

  const getDayWiseTotalSuccessfulReferredUsers = (date: string) => {
    const result: any = dayWiseTotalSuccessfulReferredUsers.find(
      (item: any) => item.date === date
    );
    return result ? result.count : 0;
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange]);

  const formatDataForCSV = () => {
    const csvData = [];
    csvData.push([
      "Date",
      "Total Success Deposit Amount",
      "Total Success Withdrawal Amount",
      "Total Savings",
      "Total Registered User",
      "Total Played Game",
      "Total Played Rounds",
      "Total Referred Users",
      "Total Completed KYC",
    ]);

    dateArray.forEach((date) => {
      csvData.push([
        date,
        getDayWisetotalSuccessDepositAmount(date),
        getDayWisetotalSuccessWithdrawalAmount(date),
        getDayWisetotalSuccessDepositAmount(date) -
          getDayWisetotalSuccessWithdrawalAmount(date),
        getDayWiseTotalUserCount(date),
        getDayWiseTotalGames(date),
        getDayWiseTotalGameRounds(date),
        getDayWiseTotalSuccessfulReferredUsers(date),
        getDayWiseTotalCompletedKyc(date),
      ]);
    });

    return csvData;
  };

  return (
    <Box>
      <h2 style={{ fontFamily: "Playfair Display" }}>Daily Report</h2>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
          marginTop: "10px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p style={{ marginRight: "10px" }}>
            <strong>Select Date Range:</strong>

            <DateRangePicker value={dateRange} onChange={handleDateSelect} />
          </p>
        </div>

        <div>
          <CSVLink
            data={formatDataForCSV()}
            filename={`daily_report_${formatDate(dateRange[0])}_${formatDate(
              dateRange[1]
            )}.csv`}
            className="csv-download-link"
          >
            <span>Download CSV</span>
          </CSVLink>
        </div>
      </div>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Display data in a DataGrid */}
          <div style={{ height: dynamicHeight, width: "100%" }}>
            <DataGrid
              rows={dateArray
                .map((date) => ({
                  id: date,
                  date: date,
                  totalSuccessDepositAmount:
                    getDayWisetotalSuccessDepositAmount(date),
                  totalSuccessWithdrawalAmount:
                    getDayWisetotalSuccessWithdrawalAmount(date),
                  totalSavings:
                    getDayWisetotalSuccessDepositAmount(date) -
                    getDayWisetotalSuccessWithdrawalAmount(date),
                  totalRegisteredUser: getDayWiseTotalUserCount(date),
                  totalPlayedGame: getDayWiseTotalGames(date),
                  totalPlayedRounds: getDayWiseTotalGameRounds(date),
                  totalReferredUsers:
                    getDayWiseTotalSuccessfulReferredUsers(date),
                  totalCompletedKyc: getDayWiseTotalCompletedKyc(date),
                }))
                .reverse()}
              columns={[
                {
                  field: "date",
                  headerName: "Date",
                  flex: breakpoints.sm,
                  valueGetter: (params) => {
                    const date = params.row.date;
                    const formattedDate = formatDate(new Date(date));
                    return formattedDate.split("-").reverse().join("-");
                  },
                },

                {
                  field: "totalSuccessDepositAmount",
                  headerName: "Total Success Deposit Amount",
                  flex: breakpoints.lg,
                },
                {
                  field: "totalSuccessWithdrawalAmount",
                  headerName: "Total Success Withdrawal Amount",
                  flex: breakpoints.lg,
                },
                {
                  field: "totalSavings",
                  headerName: "Total Savings",
                  flex: breakpoints.md,
                },
                {
                  field: "totalRegisteredUser",
                  headerName: "Total Registered User",
                  flex: breakpoints.md,
                },
                {
                  field: "totalPlayedGame",
                  headerName: "Total Played Game",
                  flex: breakpoints.md,
                },
                {
                  field: "totalPlayedRounds",
                  headerName: "Total Played Rounds",
                  flex: breakpoints.md,
                },
                {
                  field: "totalReferredUsers",
                  headerName: "Total Referred Users",
                  flex: breakpoints.md,
                },
                {
                  field: "totalCompletedKyc",
                  headerName: "Total Completed KYC",
                  flex: breakpoints.md,
                },
              ]}
            />
          </div>
        </>
      )}
    </Box>
  );
};
export default DailyReport;
