import React, { useState, useEffect } from "react";
import {
    Box,
    CircularProgress,
    Grid,
    Typography,
} from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import StatsCard from "../components/StatsCard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";
import { Android } from "@mui/icons-material";

const AppVersionsDashboard = () => {
    const [loading, setLoading] = useState(false);
    const [appCodeCounts, setAppCodeCounts] = useState<any[]>([]);
    const [appVersionCounts, setAppVersionCounts] = useState<any[]>([]);
    const currentDate = new Date();
    const defaultStartDate = new Date('2024-01-01T00:00:00.000Z');
    const [dateRange, setDateRange] = useState<[Date, Date]>([defaultStartDate, currentDate]);

    const colorPalette = [
        "#ADD8E6",
        "#6699CC",
        "#009933",
        "#FF33BB",
        "#B033FF",
        "#FFC933",
    ];

    const handleDateSelect = (value: any) => {
        setDateRange(value);
    };

    const fetchData = async () => {
        try {
            const user = getItemWithExpiry("user");
            if (!user.token) {
                toast.error("You have no access");
                return;
            }

            const startDateFetch = dateRange[0];
            startDateFetch.setHours(0, 0, 0, 0);
            const startDate = new Date(
                startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
            ).toJSON();

            const endDateFetch = dateRange[1];
            endDateFetch.setHours(23, 59, 59, 999);
            const endDate = new Date(
                endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
            ).toJSON();

            const filter = {
                startDate,
                endDate,
            };

            setLoading(true);

            const appVersionsDashboardResponse = await axios.get(
                `${config.REST_ENDPOINT}/api/users/appVersions/dashboard?key=${config.MAINTENANCE_BYPASS_KEY
                }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );

            const data = appVersionsDashboardResponse.data[0];
            setAppCodeCounts(data.facet.appCodeCounts.buckets || []);
            setAppVersionCounts(data.facet.appVersionCounts.buckets || []);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [dateRange]);

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "auto",
                    marginBottom: "auto",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <h2 style={{ fontFamily: "Playfair Display" }}>App Versions Dashboard</h2>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                    marginRight: "20px",
                    marginTop: "10px",
                }}
            >
                <p style={{ marginRight: "10px" }}>
                    <strong>Select Date Range:</strong>
                </p>
                <DateRangePicker value={dateRange} onChange={handleDateSelect} />
            </div>

            <div style={{ marginTop: "20px" }}>
                <Typography
                    variant="h6"
                    style={{
                        textAlign: "center",
                        marginBottom: 20,
                        backgroundColor: "#2196F3",
                        color: "white",
                    }}
                >
                    App Versions
                </Typography>

                {appVersionCounts.length === 0 ? (
                    <Typography variant="h6" align="center">
                        No app versions available
                    </Typography>
                ) : (
                    <Grid container spacing={2}>
                        {appVersionCounts.filter(version => version._id).map((version, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <StatsCard
                                    title={
                                        <Typography fontWeight="bold">
                                            App Version: {version._id}
                                        </Typography>
                                    }
                                    value={version.count}
                                    icon={
                                        <Android
                                            style={{
                                                fontSize: "20px",
                                                color: colorPalette[index % colorPalette.length],
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </div>

            <div style={{ marginTop: "20px" }}>
                <Typography
                    variant="h6"
                    style={{
                        textAlign: "center",
                        marginBottom: 20,
                        backgroundColor: "#2196F3",
                        color: "white",
                    }}
                >
                    App Codes
                </Typography>

                {appCodeCounts.length === 0 ? (
                    <Typography variant="h6" align="center">
                        No app codes available
                    </Typography>
                ) : (
                    <Grid container spacing={2}>
                        {appCodeCounts.filter(code => code._id).map((code, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <StatsCard
                                    title={
                                        <Typography fontWeight="bold">
                                            App Code: {code._id}
                                        </Typography>
                                    }
                                    value={code.count}
                                    icon={
                                        <Android
                                            style={{
                                                fontSize: "20px",
                                                color: colorPalette[index % colorPalette.length],
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </div>

            {/* Loading Spinner */}
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "auto",
                        marginBottom: "auto",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

export default AppVersionsDashboard;