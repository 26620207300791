import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SimpleTable from "../../components/SimpleTable";

const WinningsPrizeModal = (props: any) => {
  const { open, close, data = [],  } = props;
  const { winningPrizes = [] , name} = data;

  const winninPrizesColumns = [
    { field: "minRank", headerName: "Min Rank", flex: 1 },
    { field: "maxRank", headerName: "Max Rank", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "percentage", headerName: "Percentage", flex: 1 },
  ];
  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <DialogTitle>Winning Prize for tournament - {name}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <SimpleTable columns={winninPrizesColumns} data={winningPrizes} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WinningsPrizeModal;
