import React, { useState } from "react";
import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  usePermissions,
  FunctionField,
  FilterForm,
  DateTimeInput,
  SelectArrayInput,
  FilterButton,
  useNotify,
} from "react-admin";
import Big from "big.js";
import UserNameHyperlink from "../../../components/UserNameHyperlink";
import { Stack } from "rsuite";
import {
  Typography,
  Button,
  Modal,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { callRestApi } from "../../../utils/callRestApi";

const AvtGameHistory = (props: any) => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [crashData, setCrashData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const notify = useNotify();

  const fetchTopUserData = async (roundNo: any) => {
    setLoading(true);
    setSelectedUser(roundNo);

    try {
      const filter = roundNo
        ? encodeURIComponent(JSON.stringify({ roundNo }))
        : "";

      const apiUrl = `/api/crash-history?count=100&filter=${encodeURIComponent(
        JSON.stringify({ roundNo })
      )}&key=ttYXm2U4V1Qwah5893ewp78494XP19IZMl&skip=0&sortBy=createdAt&sortDir=-1`;

      const apiResponse = await callRestApi(apiUrl, "GET");

      console.log("API Response:", apiResponse);

      if (apiResponse && apiResponse.status === 200) {
        console.log("API Data:", apiResponse.data);
        if (apiResponse.data && apiResponse.data.items) {
          setCrashData(apiResponse.data.items);
          setOpenModal(true);
        } else {
          notify("No data found in the response", { type: "warning" });
        }
      } else {
        notify("Failed to fetch crash history data", { type: "error" });
      }
    } catch (error) {
      console.error("Error fetching crash history data:", error);
      notify("Error fetching crash history data", { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => setOpenModal(false);

  const GameHistoryModal = () => (
    <Modal open={openModal} onClose={closeModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 1000,
          maxHeight: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Round History Details
        </Typography>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Server Seed</TableCell>
                <TableCell>Crash Value</TableCell>
                <TableCell>Players</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {crashData.map((item: any) => (
                <TableRow key={item._id}>
                  <TableCell
                    sx={{
                      maxWidth: 200,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.serverSeed}
                  </TableCell>
                  <TableCell>{item.crashValue}x</TableCell>
                  <TableCell>
                    {item.players.map((player: any) => (
                      <Box key={player._id} sx={{ mb: 1 }}>
                        <UserNameHyperlink
                          record={player}
                          source="username"
                          to="userId"
                        />
                      </Box>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Button
          onClick={closeModal}
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Outcome"
      source="outcome"
      choices={[
        { id: "won", name: "Won" },
        { id: "lost", name: "Lost" },
      ]}
    />,
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search User Name"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Round No"
        source="roundNo"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Bet Amount"
        source="startAmount"
        resettable
        alwaysOn
      />
    </Filter>
  );
  const valueFormatter = (value: any) => {
    if (typeof value !== "undefined" && value !== null && !isNaN(value)) {
      const formattedValue = new Big(value);
      return `₹${formattedValue.toFixed(2)}`;
    }
    return "";
  };

  const valueFormatterForRoundNo = (value: any) => {
    if (typeof value !== "undefined" && value !== null && !isNaN(value)) {
      return `${value}`;
    }
    return "";
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Aviator Game Histories</h2>

      <GameHistoryModal />

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <FunctionField
            label="Round No"
            render={(record: any) => valueFormatterForRoundNo(record.roundNo)}
          />
          <UserNameHyperlink source="userName" to="userId" label="User Name" />
          <FunctionField
            label="Bet Amount"
            render={(record: any) => valueFormatter(record.startAmount)}
          />
          <FunctionField
            label="CashOut Amount"
            render={(record: any) => valueFormatter(record.endAmount)}
          />
          <TextField source="cashoutValue" label="Cashout value" />
          <FunctionField
            label="Outcome"
            render={(record: any) => (
              <span
                style={{
                  color: record.outcome === "won" ? green[500] : red[500],
                  fontWeight: "bold",
                }}
              >
                {record.outcome.charAt(0).toUpperCase() +
                  record.outcome.slice(1)}
              </span>
            )}
          />
          <DateField source="createdAt" showTime label="Created at" />
          <FunctionField
            label="Top User"
            render={(record: any) => (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => fetchTopUserData(record.roundNo)}
                disabled={loading && selectedUser === record.roundNo}
              >
                {loading && selectedUser === record.roundNo ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "View Top User"
                )}
              </Button>
            )}
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default AvtGameHistory;
