import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import StatsCard from "../components/StatsCard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";

const GameplayDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [revenueStats, setRevenueStats] = useState<any>({});
  const [gameDashboardStats, setGameDashboardStats] = useState<any>({});

  var date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);

  const [gameType, setGameType] = useState("all");
  const colorPalette = [
    "#ADD8E6",
    "#6699CC",
    "#3AA6B9",
    "#FF33BB",
    "#B033FF",
    "#FFC933",
  ];

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const handleGameTypeSelect = (selectedGameType: string) => {
    setGameType(selectedGameType);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
        gameType,
      };

      setLoading(true);
      // const revenueResponse = await axios.get(
      //   `${config.REST_ENDPOINT}/api/users/revenue/report?key=${
      //     config.MAINTENANCE_BYPASS_KEY
      //   }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${user.token}`,
      //     },
      //   }
      // );

      const gameDashboardResponse = await axios.get(
        `${config.REST_ENDPOINT}/api/ludo-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );


      setGameDashboardStats(gameDashboardResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange, gameType]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Ludo Game Dashboard</h2>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Grid
          container
          justifyContent="left"
          style={{ marginTop: "10px", marginBottom: "20px" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Quick Game</Typography>
              }
              value={gameDashboardStats["Total quick Games count"] ?? 0}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[0 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Quick Amount</Typography>
              }
              value={`₹${gameDashboardStats["Total quick JoinFee count"]  ?? 0}`}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[1 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              title={
                <Typography fontWeight="bold">
                  Total Quick Commission
                </Typography>
              }
              value={`₹${gameDashboardStats["Total quick Commission"] ?? 0}`}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[2 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="left"
          style={{ marginTop: "10px", marginBottom: "20px" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Classic Game</Typography>
              }
              value={gameDashboardStats["Total classic Games count"] ?? 0}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[0 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Classic Amount</Typography>
              }
              value={`₹${gameDashboardStats["Total classic JoinFee count"] ?? 0}`}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[1 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              title={
                <Typography fontWeight="bold">
                  Total Classic Commission
                </Typography>
              }
              value={`₹${gameDashboardStats["Total classic Commission"] ?? 0}`}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[2 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="left"
          style={{ marginTop: "10px", marginBottom: "20px" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Furious4 Game</Typography>
              }
              value={gameDashboardStats["Total furious4 Games count"] ?? 0}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[0 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Furious4 Amount</Typography>
              }
              value={`₹${gameDashboardStats["Total furious4 JoinFee count"] ?? 0}`}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[1 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              title={
                <Typography fontWeight="bold">
                  Total Furious4 Commission
                </Typography>
              }
              value={`₹${gameDashboardStats["Total furious4 Commission"] ?? 0}`}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[2 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        justifyContent="left"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <StatsCard
            title={<Typography fontWeight="bold">Total Games</Typography>}
            value={gameDashboardStats["Total Games"] ?? 0}
            icon={
              <SportsEsportsIcon
                style={{
                  fontSize: "20px",
                  color: colorPalette[0 % colorPalette.length],
                }}
              />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StatsCard
            title={<Typography fontWeight="bold">Total Amount</Typography>}
            value={`₹${gameDashboardStats["Total Amount"] ?? 0}`}
            icon={
              <SportsEsportsIcon
                style={{
                  fontSize: "20px",
                  color: colorPalette[1 % colorPalette.length],
                }}
              />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StatsCard
            title={<Typography fontWeight="bold">Total Commission</Typography>}
            value={`₹${gameDashboardStats["Total Commission"] ?? 0}`}
            icon={
              <SportsEsportsIcon
                style={{
                  fontSize: "20px",
                  color: colorPalette[2 % colorPalette.length],
                }}
              />
            }
          />
        </Grid>
      </Grid>
      {/* </div> */}

      <div style={{ marginTop: "20px" }}>
        <Typography
          variant="h6"
          style={{
            marginTop: -20,
            textAlign: "center",
            marginBottom: 20,
            backgroundColor: "#2196F3",
            color: "white",
          }}
        >
          Quick Game Join Amount And Tables
        </Typography>

        {Object.keys(gameDashboardStats.quickJoinAmountAndTable ?? {})
          .length === 0 ? (
          <Typography variant="h6" align="center">
            There is no joining fee and tables are available.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {Object.entries(gameDashboardStats.quickJoinAmountAndTable).map(
              ([key, value], index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <StatsCard
                    title={
                      <Typography fontWeight="bold">
                        Join Amount {`₹${key}`}
                      </Typography>
                    }
                    value={value}
                    subheader={
                      <Typography fontWeight="bold">
                        Tables: {value as number}
                      </Typography>
                    }
                    icon={
                      <SportsEsportsIcon
                        style={{
                          fontSize: "20px",
                          color: colorPalette[index % colorPalette.length],
                        }}
                      />
                    }
                  />
                </Grid>
              )
            )}
          </Grid>
        )}

        <div style={{ marginTop: "20px" }}>
          <Typography
            variant="h6"
            style={{
              marginTop: -20,
              textAlign: "center",
              marginBottom: 20,
              backgroundColor: "#2196F3",
              color: "white",
            }}
          >
            Classic Game Join Amount And Tables
          </Typography>

          {Object.keys(gameDashboardStats.classicJoinAmountAndTable ?? {})
            .length === 0 ? (
            <Typography variant="h6" align="center">
              There is no joining fee and tables are available.
            </Typography>
          ) : (
            <Grid container spacing={2}>
              {Object.entries(gameDashboardStats.classicJoinAmountAndTable).map(
                ([key, value], index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <StatsCard
                      title={
                        <Typography fontWeight="bold">
                          Join Amount {`₹${key}`}
                        </Typography>
                      }
                      value={value}
                      subheader={
                        <Typography fontWeight="bold">
                          Tables: {value as number}
                        </Typography>
                      }
                      icon={
                        <SportsEsportsIcon
                          style={{
                            fontSize: "20px",
                            color: colorPalette[index % colorPalette.length],
                          }}
                        />
                      }
                    />
                  </Grid>
                )
              )}
            </Grid>
          )}

          <div style={{ marginTop: "20px" }}>
            <Typography
              variant="h6"
              style={{
                marginTop: -20,
                textAlign: "center",
                marginBottom: 20,
                backgroundColor: "#2196F3",
                color: "white",
              }}
            >
              Furious4 Game Join Amount And Tables
            </Typography>

            {Object.keys(gameDashboardStats.furious4JoinAmountAndTable ?? {})
              .length === 0 ? (
              <Typography variant="h6" align="center">
                There is no joining fee and tables are available.
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {Object.entries(
                  gameDashboardStats.furious4JoinAmountAndTable
                ).map(([key, value], index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <StatsCard
                      title={
                        <Typography fontWeight="bold">
                          Join Amount {`₹${key}`}
                        </Typography>
                      }
                      value={value}
                      subheader={
                        <Typography fontWeight="bold">
                          Tables: {value as number}
                        </Typography>
                      }
                      icon={
                        <SportsEsportsIcon
                          style={{
                            fontSize: "20px",
                            color: colorPalette[index % colorPalette.length],
                          }}
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </div>
      </div>
      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default GameplayDashboard;
