import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, usePermissions, useRefresh } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import { ReactQueryDevtools } from "react-query/devtools";
import config from "../../../config";
import { getItemWithExpiry } from "../../../utils";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Big from "big.js";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  Switch,
} from "@mui/material";
import { callRestApi } from "../../../utils/callRestApi";
import moment from "moment-timezone";

const GameOngoing = () => {
  const refresh = useRefresh();
  const [loading, setLoading] = useState(true);
  const [roundIdFilter, setRoundIdFilter] = useState("");
  const [sortModel, setSortModel] = useState([]);
  const [tableIdFilter, setTableIdFilter] = useState("");
  const [usernameFilter, setUsernameFilter] = useState("");
  const [pointValueFilter, setPointValueFilter] = useState("");
  const [totalLivePlayers, setTotalLivePlayers] = useState(0);
  const [totalTables, settotalTables] = useState(0);
  const [uniqueTableIds, setUniqueTableIds] = useState(0);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [tableStatus, setTableStatus] = useState("green");
  const [stuckTables, setStuckTables] = useState<string[]>([]);
  const [clearConfirmation, setClearConfirmation] = useState(false);
  const [showStuckTables, setShowStuckTables] = useState(false);
  const [tableIdToDelete, setTableIdToDelete] = useState(null);
  const [stuckTablesCount, setStuckTablesCount] = useState(0);
  const { permissions } = usePermissions();
  const isAdmin = permissions.includes("admin");

  const openClearConfirmation = (tableId: any) => {
    setTableIdToDelete(tableId);
    setClearConfirmation(true);
  };

  const closeClearConfirmation = () => {
    setClearConfirmation(false);
  };

  const handleClearConfirmation = async (confirmed: boolean) => {
    closeClearConfirmation();
    if (confirmed && tableIdToDelete) {
      await handleClearStuckTable(tableIdToDelete);
    }
  };

  const handleClearStuckTable = async (tableId: string) => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }

      const endpoint = `/admin/live-games/rummyempire/${tableId}`;
      await callRestApi(endpoint, "DELETE", {});
      // eslint-disable-next-line
      toast.success("Table deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      refresh();
    } catch (e: any) {
      toast(
        e?.response.data?.message ? e?.response.data?.message : e?.message,
        { type: "error" }
      );
    }
  };

  const breakpoints = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
  };
  const columns = [
    {
      field: "tableId",
      headerName: "Table ID",
      flex: breakpoints.md,
    },

    {
      field: "roundId",
      headerName: "Round ID",
      flex: breakpoints.md,
    },
    {
      field: "tableType.pointValue",
      headerName: "Point Value",
      flex: breakpoints.md,
      renderCell: (params: any) => {
        const pointValue = params.row.tableType?.pointValue;
        return (
          <div>
            {pointValue !== undefined && pointValue !== null
              ? new Big(pointValue).toFixed()
              : "N/A"}
          </div>
        );
      },
    },

    {
      field: "players",
      headerName: "Players",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                padding: "5px",
              }}
            >
              <Link to={`/api/users/${player.userId}/show`}>
                {player.username}
              </Link>
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "gameStatus",
      headerName: "Game Status",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                color: player.drawCard ? "green" : "red",
                padding: "5px",
              }}
            >
              {player.active ? "drawCard" : "discardCard"}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: breakpoints.sm,
      valueGetter: (params: any) => {
        return moment(params.value)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  const rows = filteredData
    ? filteredData.reduce((accumulator: any[], game: any) => {
        const existingTable = accumulator.find(
          (item) => item.tableId === game.tableId
        );

        if (existingTable) {
          existingTable.players.push(...game.players);
        } else {
          accumulator.push({
            id: game.tableId,
            tableId: game.tableId,
            gameStatus: game.gameStatus,
            roundId: game.roundId,
            updatedAt: game.updatedAt,
            tableType: game.tableType,
            pointValue: game.pointValue,
            players: [...game.players],
          });
        }

        return accumulator;
      }, [])
    : [];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const fetchGameData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      const filters = {
        userName: usernameFilter,
        tableId: tableIdFilter,
      };

      const { page, pageSize } = paginationModel;
      let query = "";
      query += `&skip=${pageSize * page}&count=${pageSize}`;
      const endpoint = "/admin/live-games/rummyempire";
      const response = await callRestApi(endpoint, "POST", filters, query);

      if (!response?.data || Object.keys(response.data).length === 0) {
        setLoading(false);
        return;
      }

      let filteredData: any[] = [...response?.data.gameTables];
      if (roundIdFilter) {
        filteredData = filteredData.filter((game) =>
          game.roundId.includes(roundIdFilter)
        );
      }
      if (pointValueFilter) {
        filteredData = filteredData.filter((game) => {
          const pointValue = game.tableType?.pointValue?.toString();
          return pointValue && pointValue.includes(pointValueFilter);
        });
      }

      if (showStuckTables) {
        const stuckTablesData = [...response?.data.stuckTables];
        filteredData = stuckTablesData;
      }

      if (
        usernameFilter ||
        roundIdFilter ||
        tableIdFilter ||
        pointValueFilter
      ) {
        settotalTables(filteredData.length);
      }

      setUniqueTableIds(response?.data.tableCount);
      setTotalLivePlayers(response?.data.playerCount);
      settotalTables(filteredData.length);
      setStuckTablesCount(response?.data.stuckTableCount);
      setStuckTables(response?.data.stuckTables);

      setFilteredData(
        filteredData.map((game) => ({
          ...game,
          id: game.tableId,
        }))
      );

      if (response?.data.tableCount > 0) {
        setTableStatus("green");
      } else {
        setTableStatus("red");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching game data:", error);
      setLoading(false);
      toast.error("Error fetching game data");
    }
  };

  useEffect(() => {
    let interval: any;
    const timeout = setTimeout(() => {
      fetchGameData();
      interval = setInterval(async () => {
        await fetchGameData();
        refresh();
      }, 20000);
    }, 800);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [
    refresh,
    usernameFilter,
    roundIdFilter,
    pointValueFilter,
    showStuckTables,
    paginationModel,
  ]);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const renderClearButton = (tableId: any) => {
    const buttonStyle = {
      marginLeft: "28px",
      padding: "6px 6px",
      backgroundColor: "#FF6500",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    };

    return isAdmin ? (
      <div>
        <button
          ref={buttonRef}
          onClick={() => openClearConfirmation(tableId)}
          style={buttonStyle}
        >
          Clear
        </button>
        <Dialog open={clearConfirmation} onClose={closeClearConfirmation}>
          <DialogTitle>Confirm Clear Table</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to clear the table?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClearConfirmation(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleClearConfirmation(true)}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : null;
  };
  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Rummy Live Games</h2>

      <div>
        <div>
          <div
            style={{
              display: "inline-block",
              marginRight: "10px",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "80%",
                backgroundColor: tableStatus,
                display: "inline-block",
                marginRight: "5px",
              }}
            />
            <b> Live Tables: {uniqueTableIds} </b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "80%",
                  backgroundColor: tableStatus,
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              <b> Live Players: {totalLivePlayers} </b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "80%",
                  backgroundColor: stuckTables.length > 0 ? "red" : "green",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              <b> Stuck Tables: {stuckTablesCount} </b>
            </div>
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by Table ID "
              variant="outlined"
              fullWidth
              value={tableIdFilter}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setTableIdFilter(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by Round Id"
              variant="outlined"
              fullWidth
              value={roundIdFilter}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setRoundIdFilter(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by UserName"
              variant="outlined"
              fullWidth
              value={usernameFilter}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setUsernameFilter(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by Point Value"
              variant="outlined"
              fullWidth
              value={pointValueFilter}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setPointValueFilter(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 0px 6px 0px",
            }}
          >
            <FormLabel component="legend">Show Stuck Tables</FormLabel>
            <Switch
              checked={showStuckTables}
              onChange={() => setShowStuckTables((prev) => !prev)}
            />
          </Grid>
          <DataGrid
            columns={columns}
            rows={rows}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 20, 50, 100]}
            rowCount={totalTables}
            paginationMode="server"
            autoHeight
            rowHeight={200}
          />
        </Paper>
        <ReactQueryDevtools />
      </div>
    </>
  );
};

export default GameOngoing;
