import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import config from "../config";

const KycZoopImageModal = (props: any) => {
  const { open, close, data } = props;
  const { kyc, username } = data;
  const kycData = kyc.data;
  
  let url = config.REST_ENDPOINT.replace("admin", "kyc") + '/';
  console.log('ENV', config, url);


  
  // if (config.NODE_ENV === "development") {
  //   url = "https://test.kyc.empiregames.in/";
  // } else if (config.NODE_ENV === "production") {
  //   url = "https://prod.kyc.empiregames.in/";
  // } else {
  //   url = "";
  // }

  const DisplayAadhar = (props: any) => {
    const { aadharFrontUrl, aadharBackUrl, aadharCardNumber } = props;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          m: "auto",
          width: "fit-content",
        }}
      >
        {kycData.cardNumber && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2 style={{ marginBottom: "10px" }}>Aadhar Card Number</h2>
            <p style={{ fontSize: "1.5em" }}>{aadharCardNumber}</p>
          </Box>
        )}
      </Box>
    );
  };

  // const DisplayPanCard = (props: any) => {
  //   let { panUrl } = props;
  //   if (url && panUrl) {
  //     const imageName = panUrl.split("/").pop();
  //     panUrl = `${url}${imageName}`;
  //   }

  const DisplayPanCard = (props: any) => {
    let { panUrl } = props;
    if (url && panUrl) {
      const imageName = panUrl.split("/").pop();
      // Extract the domain from the url
      const domain = url.replace('https://', '').replace('/', '');
      // Replace the first dot with a hyphen
      const newDomain = domain.replace(/^([^.]+)\./, '$1-');
      // Construct the new url
      panUrl = `https://${newDomain}/${imageName}`;
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 style={{ marginBottom: "10px" }}>Pan Card Image</h2>
        <img src={panUrl} alt="Pan Card" width="50%" loading="lazy" />
      </Box>
    );
  };

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
      <DialogTitle>KYC Documents of {username}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
          }}
        >
          {kycData.cardType === "aadhaar" && (
            <DisplayAadhar aadharCardNumber={kycData.cardNumber} />
          )}
          {kycData.cardType === "pan" && (
            <DisplayPanCard panUrl={kycData.imageUrl} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KycZoopImageModal;
