import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, usePermissions } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, DateRangePicker } from "rsuite";
import { CSVLink } from "react-csv";
import {
  Badge,
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import StatusIndicator from "../components/StatusIndicator";
import { getItemWithExpiry } from "../utils";
import config from "../config";

interface RowData {
  userId: string;
  username: string;
  roles: string[];
  mobileNumber: {
    countryCode: string;
    number: string;
  };
}

interface UserProfile {
  "User Id": string;
  Username: string;
  "Mobile Number": string;
  "My current Role": string;
  _id: string;
  username: string;
  numericId: number;
  mobileNo: {
    countryCode: string;
    number: string;
  };
  roles: string[];
  profilePic: string;
  lastLoginAt: string;
  canBeReferred: boolean;
  profileAvatar: number;
  registrationTime: string;
  testUser: boolean;
  isAddressValid: boolean;
  userKycStatus: string;
  referral: string;
  loggedInUserRole: string;
}

const AdminPage = () => {
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState<RowData[]>([]);
  const [filteredData, setFilteredData] = useState<RowData[]>([]);
  const [usernameFilter, setUsernameFilter] = useState("");
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [totalAdmins, settotalAdmins] = useState(0);
  const [totalFinance, setTotalFinance] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [totalSupports, setTotalSupports] = useState(0);
  const [totalDevelopers, setTotalDevelopers] = useState(0);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  const { permissions } = usePermissions();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);
  const [dateRangeKey, setDateRangeKey] = useState(0);
  const [tableStatus, setTableStatus] = useState("green");

  const breakpoints = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
  };

  const columns = [
    {
      field: "userId",
      headerName: "Username",
      flex: breakpoints.xs,
      renderCell: (params: any) => {
        if (permissions.includes("admin")) {
          return (
            <Link to={`/api/users/${params.row.userId}/show`}>
              {params.row.username}
            </Link>
          );
        } else {
          return params.row.username;
        }
      },
    },
    { field: "roles", headerName: "Roles", flex: breakpoints.xs },
    {
      field: "mobileNumber",
      headerName: "MobileNo",
      flex: breakpoints.xs,
      valueGetter: (params: any) =>
        `${params.row.mobileNumber.countryCode} ${params.row.mobileNumber.number}`,
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    filterGameData();
    // eslint-disable-next-line
  }, [gameData, usernameFilter, dateRangeKey]);

  useEffect(() => {
    fetchGameData();
    fetchUserProfile();
    // eslint-disable-next-line
  }, [paginationModel, dateRangeKey]);

  useEffect(
    () => {
      if (gameData.length > 0) {
        fetchUserProfile();
        // eslint-disable-next-line
      }
    }, // eslint-disable-next-line
    [gameData]
  );

  const fetchGameData = async () => {
    try {
      const user = getItemWithExpiry("user");

      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      let apiUrl = `${config.REST_ENDPOINT}/api/profile?key=${config.MAINTENANCE_BYPASS_KEY}`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const data = response.data;

      setGameData(data.users);
      setFilteredData(data.users);
      if (response.data.roleCounts.admin > 0) {
        setTableStatus("green");
      } else {
        setTableStatus("red");
      }
      setTotalItemsCount(data.meta.perPage);
      setTotalSupports(data.roleCounts.support);
      setTotalFinance(data.roleCounts.finance);
      settotalAdmins(data.roleCounts.admin);
      setTotalDevelopers(data.roleCounts.developer);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching game data:", error);
      setLoading(false);
      toast.error("Error fetching game data");
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const fetchUserProfile = async () => {
    try {
      const user = getItemWithExpiry("user");

      if (!user || !user.token) {
        throw new Error("You have no access");
      }

      let apiUrl = `${config.REST_ENDPOINT}/api/profile`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const userProfileData = response.data.roleObject;

      setUserProfile(userProfileData);
    } catch (error: any) {
      console.error("Error fetching user profile:", error);
      toast.error(error.message || "Error fetching user profile");
    }
  };

  const filterGameData = () => {
    let filteredData = gameData;

    if (usernameFilter) {
      filteredData = filteredData.filter((item: any) =>
        item.username?.toLowerCase().includes(usernameFilter.toLowerCase())
      );
    }

    setFilteredData(filteredData);
  };

  const handleDateSelect = (value: any) => {
    setDateRange(value);
    setDateRangeKey((prevKey) => prevKey + 1);
  };

  const csvData = React.useMemo(() => {
    return filteredData.map((item: any) => ({
      Username: item.username,
      Roles: item.roles,
      "Mobile No": item.mobileNumber.number,
    }));
  }, [filteredData]);

  const getRowId = (row: RowData) => {
    const { roles, mobileNumber } = row;
    const roleString = roles.join("");
    const mobileNumberString = `${mobileNumber.countryCode}${mobileNumber.number}`;
    return `${roleString}-${mobileNumberString}`;
  };

  return (
    <div>
      <h2 style={{ fontFamily: "Playfair Display" }}>Admin Profile</h2>

      {userProfile && (
        <Card
          elevation={3}
          style={{
            marginBottom: "20px",
            textAlign: "left",
            width: "300px",
            height: "250px",
            margin: "auto",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                fontFamily: "Playfair Display",
                marginBottom: "20px",
                color: "#007bff",
              }}
            >
              Profile Information
            </Typography>
            <Box
              style={{
                marginBottom: "20px",
              }}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                style={{ marginLeft: "85px" }}
              >
                <Avatar
                  alt="Skill Patti"
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                />
              </StyledBadge>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body1"
                style={{ marginBottom: "8px", color: "#555" }}
              >
                <strong>Username:</strong> {userProfile["Username"]}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: "8px", color: "#555" }}
              >
                <strong>Mobile Number:</strong> {userProfile["Mobile Number"]}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: "8px", color: "#555" }}
              >
                <strong>My Current Role:</strong>{" "}
                {userProfile["My current Role"]}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}

      <div>
        <div
          style={{
            display: "inline-block",
            marginRight: "10px",
            marginTop: "5px",
          }}
        >
          <StatusIndicator
            backgroundColor={tableStatus}
            total={totalAdmins}
            label="Total Admin Users"
          />
          <StatusIndicator
            backgroundColor={tableStatus}
            total={totalFinance}
            label="Total Finance Users"
          />
          <StatusIndicator
            backgroundColor={tableStatus}
            total={totalSupports}
            label="Total Support Users"
          />
          <StatusIndicator
            backgroundColor={tableStatus}
            total={totalDevelopers}
            label="Total Developer users"
          />
        </div>
      </div>

      <Grid container spacing={2}>
        {
          <>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Search by Username"
                variant="outlined"
                fullWidth
                value={usernameFilter}
                onChange={(e) => {
                  setPaginationModel({ page: 0, pageSize: 10 });
                  setUsernameFilter(e.target.value);
                }}
              />
            </Grid>
          </>
        }
        <Grid item xs={12} sm={3}>
          {
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                marginRight: "20px",
                marginTop: "10px",
              }}
            >
              <CSVLink
                data={csvData}
                filename={`Admin_users_list_data_${new Date().toLocaleDateString()}.csv`}
                className="btn btn-primary"
                style={{ position: "absolute", left: "92.8%" }}
              >
                <span>Download CSV</span>
              </CSVLink>
            </div>
          }
        </Grid>
      </Grid>

      {
        <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
          <DataGrid
            columns={columns}
            rows={filteredData}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 20, 50]}
            autoHeight
            rowCount={totalItemsCount}
            getRowId={getRowId}
          />
        </Paper>
      }
    </div>
  );
};

export default AdminPage;
