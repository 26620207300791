import {
  List,
  TextField,
  DateField,
  ChipField,
  DatagridConfigurable,
  Filter,
  SearchInput,
} from "react-admin";
import { ReactQueryDevtools } from "react-query/devtools";
import UserNameHyperlink from "../components/UserNameHyperlink";

const BugReports = () => {
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search UserId"
        source="userId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search TableId"
        source="tableId"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Bug Reports</h2>

      <ReactQueryDevtools initialIsOpen={false} />
      <List
        resource="bug-reports"
        sort={{ field: "Created at", order: "DESC" }}
        filters={<UserNameFilter />}
      >
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="username" to="userId" />
          <TextField source="userId" label="User ID" />
          <TextField source="traceFile" />
          <TextField source="description" />
          <DateField source="createdAt" />
          <ChipField source="tableId" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default BugReports;
