import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { callRestApi } from "../utils/callRestApi";
import { toast } from "react-toastify";

const WalletTransactionModal = (props: any) => {
  const { open, close, userId, title, transaction, notify } = props;
  let isCredit = true;
  if (transaction === "credit") {
    isCredit = true;
  } else {
    isCredit = false;
  }
  const [formData, setFormData] = useState({
    amount: "",
    selectedField: "amount",
    orderId: "",
    tableId: "",
    wallet: "",
    game: "",
    credit: isCredit,
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    setMessage("");
    if (!formData.wallet) {
      setMessage("Wallet is a mandatory field");
      return;
    }

    if (formData.selectedField === "orderId" && !formData.orderId) {
      setMessage("Order ID is mandatory when selected.");
      return;
    }
    if (formData.selectedField === "tableId" && !formData.tableId) {
      setMessage("Table ID is mandatory when selected.");
      return;
    }

    try {
      setLoading(true);
      const { selectedField, ...formDataWithoutSelectedField } = formData;
      const endpoint = "/transactions/admin-refund";
      const response = await callRestApi(endpoint, "POST", {
        userId: userId,
        ...formDataWithoutSelectedField,
      });

      if (response?.status === 201) {
        toast.success(response?.data.message || "Wallet Updated. Please check");
        setLoading(false);
        close();
      } 
    } catch (error:any) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          {!message ? null : (
            <Typography variant="h6" color="error">
              {message}
            </Typography>
          )}
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <FormControl fullWidth>
            <TextField
              id="amount"
              type="text"
              label="Amount"
              variant="outlined"
              fullWidth
              value={formData.amount}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  amount: event.target.value,
                }));
              }}
            />
            <RadioGroup
              row
              aria-label="Field"
              name="selectedField"
              value={formData.selectedField}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  selectedField: event.target.value,
                }));
              }}
            >
              <FormControlLabel
                value="orderId"
                control={<Radio />}
                label="Order ID"
              />
              <FormControlLabel
                value="tableId"
                control={<Radio />}
                label="Table ID"
              />
            </RadioGroup>
            {formData.selectedField === "orderId" ? (
              <TextField
                id="orderId"
                label="Order ID"
                fullWidth
                variant="outlined"
                required
                value={formData.orderId}
                onChange={(event) => {
                  setFormData((prev) => ({
                    ...prev,
                    orderId: event.target.value,
                  }));
                }}
              />
            ) : (
              <TextField
                id="tableId"
                label="Table ID"
                fullWidth
                variant="outlined"
                required
                value={formData.tableId}
                onChange={(event) => {
                  setFormData((prev) => ({
                    ...prev,
                    tableId: event.target.value,
                  }));
                }}
              />
            )}
          </FormControl>
          <FormControl>
            <FormLabel id="gameLabel">Select Game</FormLabel>
            <RadioGroup
              row
              aria-labelledby="gameLabel"
              name="game"
              value={formData.game}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  game: event.target.value,
                }));
              }}
            >
              <FormControlLabel value="ludo" control={<Radio />} label="Ludo" />
              <FormControlLabel
                value="skillpatti"
                control={<Radio />}
                label="Skillpatti"
              />
              <FormControlLabel
                value="callbreak"
                control={<Radio />}
                label="Callbreak"
              />
                 <FormControlLabel
                value="snakesandladders"
                control={<Radio />}
                label="Snakeandladder"
              />
                <FormControlLabel
                value="rummy"
                control={<Radio />}
                label="Rummy"
              />
                <FormControlLabel
                value="handcricket"
                control={<Radio />}
                label="Handcricket"
              />
               <FormControlLabel
                value="aviator"
                control={<Radio />}
                label="Aviator"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel id="walletLabel" required>
              Select Wallet
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="walletLabel"
              name="wallet"
              value={formData.wallet}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  wallet: event.target.value,
                }));
              }}
            >
              <FormControlLabel
                value="main"
                control={<Radio />}
                label="Main "
              />
              <FormControlLabel
                value="win"
                control={<Radio />}
                label="Win "
              />
              <FormControlLabel
                value="bonus"
                control={<Radio />}
                label="Bonus"
              />
             
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="info" variant="contained">
          Close
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={onSave}
          disabled={loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletTransactionModal;
