import { useRecordContext } from "react-admin";

const TotalGamesPlayedField = ({ gameType, label }: any) => {
  const record = useRecordContext();
  if (!record || !record.stats || !record.stats[gameType]) return null;

  const totalGamesPlayed =
    (record.stats[gameType].winMatches || 0) +
    (record.stats[gameType].lossMatches || 0);
  const earnedMoney = (record.stats[gameType].earnedMoney || 0).toFixed(2);
  const winMatches = record.stats[gameType].winMatches || 0;
  const lossMatches = record.stats[gameType].lossMatches || 0;

  return (
    <div>
      <div>
        <b>Total Games Played: </b>
        {totalGamesPlayed}
      </div>
      <div>
        <b>Total Earnings: </b>
        ₹{earnedMoney}
      </div>
      <div>
        <b>Total Won: </b>
        {winMatches}
      </div>
      <div>
        <b>Total Lost: </b>
        {lossMatches}
      </div>
    </div>
  );
};

TotalGamesPlayedField.defaultProps = { addLabel: true };

export default TotalGamesPlayedField;
