import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useRefresh, usePermissions } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import { ReactQueryDevtools } from "react-query/devtools";
import config from "../../../config";
import { getItemWithExpiry } from "../../../utils";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormLabel,
  Switch,
} from "@mui/material";
import { callRestApi } from "../../../utils/callRestApi";

interface Player {
  playerId: string;
  userId: string;
  name: string | null;
  username: string;
}

interface WaitingUser {
  userId: string;
  name: string | null;
  username: string;
}

interface Table {
  tableId: string;
  joinFee: string;
  startedAt: string;
  updatedAt: string;
  players: (Player | WaitingUser)[];
  status?: string;
}

interface GameData {
  tables: Table[];
}

interface WaitingUserGame {
  joinFee: string;
  players: WaitingUser[][];
}

interface ApiResponse {
  gameTables: GameData[];
  waitingUserList: WaitingUserGame[];
}

const EplLiveGame = () => {
  const refresh = useRefresh();
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState<Table[]>([]);
  const [usernameFilter, setUsernameFilter] = useState("");
  const [tableIdFilter, setTableIdFilter] = useState("");
  const [amountFilter, setAmountFilter] = useState("");
  const [liveUserCount, setLiveUserCount] = useState(0);
  const [liveTableCount, setLiveTableCount] = useState(0);
  const [waitingUsersCount, setWaitingUsersCount] = useState(0);
  const [stuckTableCount, setStuckTableCount] = useState(0);
  const [stuckTables, setStuckTables] = useState<string[]>([]);
  const [clearConfirmation, setClearConfirmation] = useState(false);
  const [tableIdToDelete, setTableIdToDelete] = useState<string | null>(null);
  const [showStuckTables, setShowStuckTables] = useState(false);
  const [showWaitingUsers, setShowWaitingUsers] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { permissions } = usePermissions();
  const isAdmin = permissions.includes("admin");
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const columns = [
    { field: "tableId", headerName: "Table ID", flex: 1 },
    { field: "joinFee", headerName: "Join Fee", flex: 1 },
    {
      field: "players",
      headerName: "Players",
      flex: 1,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: Player | WaitingUser) => (
            <div key={player.userId} style={{ padding: "5px" }}>
              <Link to={`/api/users/${player.userId}/show`}>
                {player.username}
              </Link>
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params: any) => (
        <div
          style={{
            color: params.row.status === "live" ? "green" : params.row.status === "waiting" ? "red" : "orange",
          }}
        >
          {params.row.status || "live"}
        </div>
      ),
    },
    {
      field: "startedAt",
      headerName: "Started At",
      flex: 1,
      renderCell: (params: any) => (
        <div>{new Date(params.row.startedAt).toLocaleString()}</div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params: any) => (
        <div>
          {stuckTables.includes(params.row.tableId) &&
            renderClearButton(params.row.tableId)}
        </div>
      ),
    },
  ];

  const checkStuckTables = (tables: Table[]) => {
    console.log("Checking for stuck tables...");
    const currentTime = new Date();
    const updatedStuckTables = tables
      .filter((table) => {
        const updatedAtTime = new Date(table.updatedAt);
        const timeDifference =
          (currentTime.getTime() - updatedAtTime.getTime()) / (1000 * 60);
        return timeDifference >= 1;
      })
      .map((stuckTable) => stuckTable.tableId);

    console.log("Found stuck tables:", updatedStuckTables);
    setStuckTables(updatedStuckTables);
    setStuckTableCount(updatedStuckTables.length);
  };

  const fetchTotalCounts = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("Authentication required");
        return;
      }

      const response = await axios.get<ApiResponse>(
        `${config.AUTH_ENDPOINT}/admin/live-games/handcricket?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      console.log("API Response:", response.data);

      const gameTables = response.data.gameTables || [];
      const flattenedTables = gameTables.flatMap((game) => game.tables || []);

      setLiveTableCount(flattenedTables.length);
      const totalLiveUsers = flattenedTables.reduce(
        (acc, table) => acc + table.players.length,
        0
      );
      setLiveUserCount(totalLiveUsers);

      const waitingUserList = response.data.waitingUserList || [];
      const flattenedWaitingUsers = waitingUserList.flatMap((game) =>
        game.players.flat()
      );
      const totalWaitingUsers = flattenedWaitingUsers.length;
      setWaitingUsersCount(totalWaitingUsers);

      checkStuckTables(flattenedTables);

      console.log("Live Users:", liveUserCount);
      console.log("Live Tables:", liveTableCount);
    } catch (error: any) {
      console.error("Error fetching total counts:", error);
      toast.error(
        error?.response?.data?.message || error?.message || "An error occurred"
      );
    }
  };

  const fetchGameData = async () => {
    console.log("Starting fetchGameData...");
    try {
      setLoading(true);
      const user = getItemWithExpiry("user");

      if (!user || !user.token) {
        toast.error("Authentication required");
        return;
      }

      const { page, pageSize } = paginationModel;
      let query = "";
      query += usernameFilter ? `&userName=${usernameFilter}` : "";
      query += tableIdFilter ? `&tableId=${tableIdFilter}` : "";
      query += amountFilter ? `&amount=${amountFilter}` : "";
      query += `&skip=${pageSize * page}&count=${pageSize}`;

      const response = await axios.get<ApiResponse>(
        `${config.AUTH_ENDPOINT}/admin/live-games/handcricket?key=${config.MAINTENANCE_BYPASS_KEY}${query}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const gameTables = response.data.gameTables || [];
      const waitingUserList = response.data.waitingUserList || [];

      const liveTables = gameTables.flatMap((game) => game.tables || []);
      const waitingUsers = waitingUserList.flatMap((game) =>
        game.players.flat()
      );

      let filteredTables = liveTables;

      if (tableIdFilter) {
        filteredTables = filteredTables.filter(
          (table) => table.tableId.toLowerCase() === tableIdFilter.toLowerCase()
        );
      }

      if (usernameFilter) {
        filteredTables = filteredTables.filter((table) =>
          table.players.some(
            (player) =>
              player.username.toLowerCase() === usernameFilter.toLowerCase()
          )
        );
      }

      if (amountFilter) {
        filteredTables = filteredTables.filter(
          (table) => table.joinFee === amountFilter
        );
      }

      if (showStuckTables) {
        const stuckFilteredData = filteredTables.filter((table) =>
          stuckTables.includes(table.tableId)
        );
        setGameData(stuckFilteredData);
      } else if (showWaitingUsers) {
        const waitingUserData = waitingUserList.flatMap((game) =>
          game.players.map((userGroup) =>
            userGroup.map((user) => ({
              tableId: "Waiting",
              joinFee: game.joinFee,
              players: [user],
              startedAt: "N/A", 
              status: "waiting",
              updatedAt: new Date().toISOString(),
            }))
          )
        ).flat();
        setGameData(waitingUserData);
      } else {
        setGameData(filteredTables);
      }
    } catch (error: any) {
      console.error("Error in fetchGameData:", error);
      toast.error(
        error?.response?.data?.message || error?.message || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClearStuckTable = async (tableId: string) => {
    console.log("Attempting to clear stuck table:", tableId);
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("Authentication required");
        return;
      }

      const endpoint = `/admin/live-games/handcricket/${tableId}`;
      await callRestApi(endpoint, "DELETE", {});

      console.log("Successfully cleared table:", tableId);
      setStuckTables((prev) => prev.filter((id) => id !== tableId));
      toast.success("Table cleared successfully");
      refresh();
    } catch (error: any) {
      console.error("Error clearing stuck table:", error);
      toast.error(
        error?.response?.data?.message || error?.message || "Failed to clear table"
      );
    }
  };

  const openClearConfirmation = (tableId: string) => {
    setTableIdToDelete(tableId);
    setClearConfirmation(true);
  };

  const closeClearConfirmation = () => {
    setClearConfirmation(false);
    setTableIdToDelete(null);
  };

  const handleClearConfirmation = async (confirmed: boolean) => {
    if (confirmed && tableIdToDelete) {
      await handleClearStuckTable(tableIdToDelete);
    }
    closeClearConfirmation();
  };

  const handleSwitchChange = (type: "stuck" | "waiting") => {
    if (type === "stuck") {
      console.log("Toggling stuck tables view");
      setShowStuckTables((prev) => !prev);
      setShowWaitingUsers(false);
    } else {
      console.log("Toggling waiting users view");
      setShowWaitingUsers((prev) => !prev);
      setShowStuckTables(false);
    }
  };

  const renderClearButton = (tableId: string) => {
    if (!isAdmin) return null;

    return (
      <div>
        <button
          ref={buttonRef}
          onClick={() => openClearConfirmation(tableId)}
          style={{
            marginLeft: "28px",
            padding: "2px 2px",
            backgroundColor: "#FF6500",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Clear
        </button>
      </div>
    );
  };

  useEffect(() => {
    console.log("Setting up data fetch interval...");
    let interval: NodeJS.Timeout;

    const timeout = setTimeout(() => {
      fetchTotalCounts();
      fetchGameData();
      interval = setInterval(() => {
        fetchTotalCounts();
        fetchGameData();
      }, 20000);
    }, 800);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [
    tableIdFilter,
    usernameFilter,
    amountFilter,
    showStuckTables,
    showWaitingUsers,
    paginationModel,
  ]);

  const rows = gameData.map((table) => ({
    id: table.tableId,
    tableId: table.tableId,
    joinFee: table.joinFee,
    players: table.players,
    startedAt: table.startedAt,
    status: table.status || "live",
  }));

  return (
    <div>
      <h2 style={{ fontFamily: "Playfair Display" }}>HandCricket Live Games</h2>
      <div>
        <div style={{ display: "inline-block", marginRight: "10px", marginTop: "5px" }}>
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "80%",
              backgroundColor: "green",
              display: "inline-block",
              marginRight: "5px",
            }}
          />
          <b> Live Users: {liveUserCount} </b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "80%",
              backgroundColor: "green",
              display: "inline-block",
              marginRight: "5px",
            }}
          />
          <b> Live Games: {liveTableCount} </b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "80%",
              backgroundColor: "green",
              display: "inline-block",
              marginRight: "5px",
            }}
          />
          <b> Waiting Users: {waitingUsersCount} </b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "80%",
              backgroundColor: stuckTableCount > 0 ? "red" : "green",
              display: "inline-block",
              marginRight: "5px",
            }}
          />
          <b> Stuck Games: {stuckTableCount} </b>
        </div>

        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by Username"
              variant="outlined"
              fullWidth
              value={usernameFilter}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setUsernameFilter(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by Table ID"
              variant="outlined"
              fullWidth
              value={tableIdFilter}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setTableIdFilter(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by Join Fee"
              variant="outlined"
              fullWidth
              value={amountFilter}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setAmountFilter(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 0px 6px 0px",
            }}
          >
            <FormLabel component="legend">Show Stuck Tables</FormLabel>
            <Switch checked={showStuckTables} onChange={() => handleSwitchChange("stuck")} />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 0px 6px 0px",
            }}
          >
            <FormLabel component="legend">Show Waiting Users</FormLabel>
            <Switch checked={showWaitingUsers} onChange={() => handleSwitchChange("waiting")} />
          </Grid>

          <DataGrid
            columns={columns}
            rows={rows}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 20, 50]}
            rowCount={liveTableCount}
            paginationMode="server"
            autoHeight
            rowHeight={200}
            sortingMode="server"
          />
        </Paper>

        <Dialog open={clearConfirmation} onClose={closeClearConfirmation}>
          <DialogTitle>Confirm Clear Table</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to clear this table?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClearConfirmation(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleClearConfirmation(true)} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <ReactQueryDevtools />
      </div>
    </div>
  );
};

export default EplLiveGame;
