import React, { useState, useRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getItemWithExpiry } from "../utils/sessionData";
import config from "../config";
import { useFilePicker } from "use-file-picker";
import Confetti from "react-confetti";

const styles = `
.blur-background {
  filter: blur(2px);
  pointer-events: none;
  user-select: none;
}
`;

interface FileContent {
  content: string;
  size: number;
  name: string;
  type: string;
}

interface LanguageMapping {
  english: string;
  hindi: string;
}

const languageMapping: LanguageMapping = {
  english: "English",
  hindi: "Hindi",
};

interface GameMapping {
  eg: string;
  le: string;
  sp: string;
  cb: string;
  snl: string;
  re: string;
  ml: string;
  avt: string;
  hc:string;
}

const gameMapping: GameMapping = {
  eg: "Empire Games",
  le: "Ludo",
  sp: "Skillpatti",
  cb: "Callbreak",
  snl: "SnakeAndLadder",
  re: "RummyEmpire",
  ml: "MegaTournament",
  avt: "Aviator",
  hc:"HandCricket"
};

export default function GameConfig() {
  const [configState, setConfigState] = React.useState<any>({});
  const [loading, setLoading] = useState(false);
  const [gameConfigDisabled, setGameConfigDisabled] = React.useState(true);
  const [gameConfigFileName, setGameConfigFileName] = React.useState(
    "Empire Games Config"
  );
  const [openGameConfigFileSelector, { filesContent: gameConfigFilesContent }] =
    useFilePicker({
      accept: ".json",
      multiple: false,
    });

  const [localizationDisabled, setLocalizationDisabled] = React.useState(true);
  const [localizationFileName, setLocalizationFileName] = React.useState(
    "le-english-localization"
  );
  const [
    openLocalizationFileSelector,
    { filesContent: localizationFilesContent },
  ] = useFilePicker({
    accept: ".json",
    multiple: false,
  });

  const [profanityDisabled, setProfanityDisabled] = React.useState(true);
  const [profanityFileName, setProfanityFileName] =
    React.useState("Profanity Config");
  const [openProfanityFileSelector, { filesContent: profanityFilesContent }] =
    useFilePicker({
      accept: ".json",
      multiple: false,
    });

  const [gameType, setGameType] = React.useState<
    "eg" | "le" | "sp" | "cb" | "snl" | "re" | "ml" | "avt" | "hc"
  >("eg");

  const [language, setLanguage] = React.useState<"english" | "hindi">(
    "english"
  );
  const [game, setGame] = React.useState<
    "eg" | "le" | "sp" | "cb" | "snl" | "re" | "ml" | "avt" | "hc"
  >("eg");

  const [showConfetti, setShowConfetti] = useState(false);
  const mainDivRef = useRef(null);

  const handleGameTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedGame = event.target.value as keyof GameMapping;
    setGameType(selectedGame);
    setGameConfigFileName(`${gameMapping[selectedGame]} Config`);
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const selectedLanguage = event.target.value as keyof LanguageMapping;
    if (selectedLanguage === "hindi") {
      setLanguage("hindi");
    } else {
      setLanguage(selectedLanguage);
    }
  };

  const handleGameChange = (event: SelectChangeEvent<string>) => {
    const selectedGame = event.target.value as keyof GameMapping;
    setGame(selectedGame);
    setLocalizationFileName(
      `${gameMapping[selectedGame]}-${languageMapping[language]}-Localization`
    );
  };

  React.useEffect(() => {
    if (gameConfigFilesContent.length > 0) {
      setGameConfigDisabled(false);
    }
  }, [gameConfigFilesContent]);

  React.useEffect(() => {
    if (localizationFilesContent.length > 0) {
      setLocalizationDisabled(false);
    }
  }, [localizationFilesContent]);

  React.useEffect(() => {
    if (profanityFilesContent.length > 0) {
      setProfanityDisabled(false);
    }
  }, [profanityFilesContent]);

  React.useEffect(() => {
    (async () => {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      // eslint-disable-next-line
      const { data } = await axios.get(
        config.REST_ENDPOINT + `/api/game-config?game=${gameType}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setConfigState(data);
    })();
  }, [gameType]);

  const handleDownloadGameConfig = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const response = await fetch(
        config.REST_ENDPOINT + `/api/game-config?game=${gameType}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const blob = new Blob([JSON.stringify(responseData)], {
          type: "application/json",
        });

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        let configType;
        if (link.href.includes("test.admin.empiregames.in")) {
          configType = "test";
        } else if (link.href.includes("prod.admin.empiregames.in")) {
          configType = "prod";
        } else if (link.href.includes("localhost:3001")) {
          configType = "local";
        } else {
          throw new Error("Invalid URL");
        }

        link.download = `${gameType}-${configType}-config.json`;
        link.click();

        URL.revokeObjectURL(url);
        setGameConfigFileName(`${gameMapping[gameType]} Config`);

        toast.success("Config data downloaded successfully!");
      } else {
        toast.error("Failed to download config data. Please try again later.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleDownloadLocalization = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      let url =
        config.REST_ENDPOINT +
        `/api/localization?game=${game}&language=${language}`;

      const response = await fetch(
        config.REST_ENDPOINT +
          `/api/localization?game=${game}&language=${language}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const blob = new Blob([JSON.stringify(responseData)], {
          type: "application/json",
        });

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${game}-${language}-localization.json`;
        link.click();

        URL.revokeObjectURL(url);
        setLocalizationFileName(
          `${gameMapping[game]}-${languageMapping[language]}-Localization`
        );

        toast.success("Localization data downloaded successfully!");
      } else {
        toast.error(
          "Failed to download localization data. Please try again later."
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleDownloadProfanity = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const response = await fetch(config.REST_ENDPOINT + `/api/profanity`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const blob = new Blob([JSON.stringify(responseData)], {
          type: "application/json",
        });

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `eg-profanity.json`;
        link.click();

        URL.revokeObjectURL(url);
        setProfanityFileName("Profanity Config");

        toast.success("Profanity data downloaded successfully!");
      } else {
        toast.error(
          "Failed to download profanity data. Please try again later."
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const onSaveGameConfig = async () => {
    const { registrationTime } = configState;
    if (!dayjs(registrationTime).year()) {
      toast.error("Please input a valid date in the registration time!");
      return;
    }
    const fileResponse = JSON.parse(gameConfigFilesContent[0].content);

    const method = "POST";
    const payload = fileResponse;

    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      const response = await fetch(
        config.REST_ENDPOINT + `/api/game-config?game=${gameType}`,
        {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        toast.success("Config file updated successfully!");
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 5000);
      } else {
        toast.error("Failed to update game config. Please try again later.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const onSaveLocalization = async () => {
    const fileResponse = JSON.parse(localizationFilesContent[0].content);

    const method = "POST";
    const payload = fileResponse;

    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      const response = await fetch(
        config.REST_ENDPOINT +
          `/api/localization?game=${game}&language=${language}`,
        {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        toast.success("Localization file updated successfully!");
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 5000);
      } else {
        toast.error("Failed to update localization. Please try again later.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const onSaveProfanity = async () => {
    const fileResponse = JSON.parse(profanityFilesContent[0].content);

    const method = "POST";
    const payload = fileResponse;

    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const response = await fetch(config.REST_ENDPOINT + `/api/profanity`, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success("Profanity file updated successfully!");
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 5000);
      } else {
        toast.error("Failed to update profanity. Please try again later.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <style>{styles}</style>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9998,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(2px)",
          }}
          onClick={(e) => e.stopPropagation()}
        />
      )}
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <div ref={mainDivRef} className={loading ? "blur-background" : ""}>
        {showConfetti && <Confetti />}

        <h2 style={{ fontFamily: "Playfair Display", marginLeft: "10px" }}>
          Game Config
        </h2>
        <Paper
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 0.1, width: "25ch" },
            padding: "35px",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            mt={4}
            mb={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControl sx={{ width: "15%", marginRight: "20px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: "#3f51b5" }}
              >
                Select Game{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gameType}
                label="Select Game Type"
                onChange={handleGameTypeChange}
              >
                <MenuItem value="eg">Empire Games</MenuItem>
                <MenuItem value="le">Ludo Empire</MenuItem>
                <MenuItem value="sp">SkillPatti Empire</MenuItem>
                <MenuItem value="cb">CallBreak Empire</MenuItem>
                <MenuItem value="snl">Snakes and Ladders</MenuItem>
                <MenuItem value="re">Rummy Empire</MenuItem>
                <MenuItem value="ml">Mega Tournament</MenuItem>
                <MenuItem value="avt">Aviator Empire</MenuItem>
                <MenuItem value="hc">Hand Cricket Empire</MenuItem>
              </Select>
            </FormControl>
            <Button
              onClick={handleDownloadGameConfig}
              variant="contained"
              color="info"
            >
              Download {gameMapping[gameType]} Config File
            </Button>
            <Button onClick={() => openGameConfigFileSelector()}>
              Upload Latest {gameMapping[gameType]} Config File
            </Button>
            {gameConfigFilesContent.map((file, index) => (
              <p key={index} style={{ marginBottom: "0.5rem" }}>
                {file.name}
              </p>
            ))}
            <Button
              disabled={gameConfigDisabled}
              onClick={onSaveGameConfig}
              variant="contained"
            >
              Upload
            </Button>
          </Box>
        </Paper>

        <h2
          style={{
            fontFamily: "Playfair Display",
            marginTop: "12px",
            marginLeft: "10px",
          }}
        >
          Localization
        </h2>
        <Paper
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 0.1, width: "25ch" },
            padding: "35px",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            mt={4}
            mb={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControl sx={{ width: "15%", marginRight: "20px" }}>
              <InputLabel id="game-select-label" style={{ color: "#3f51b5" }}>
                Select Game
              </InputLabel>
              <Select
                labelId="game-select-label"
                id="game-select"
                value={game}
                label="Select Game"
                onChange={handleGameChange}
              >
                <MenuItem value="eg">Empire Games</MenuItem> 
                <MenuItem value="le">Ludo</MenuItem>
                <MenuItem value="sp">Skillpatti</MenuItem>
                <MenuItem value="cb">Callbreak</MenuItem>
                <MenuItem value="snl">Snakes and Ladders</MenuItem>
                <MenuItem value="re">Rummy Empire</MenuItem>
                <MenuItem value="ml">Mega Tournament</MenuItem>
                <MenuItem value="avt">Aviator Empire</MenuItem>
                <MenuItem value="hc">Hand Cricket Empire</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "15%", marginRight: "20px" }}>
              <InputLabel
                id="language-select-label"
                style={{ color: "#3f51b5" }}
              >
                Select Language
              </InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={language}
                label="Select Language"
                onChange={handleLanguageChange}
              >
                <MenuItem value="english">English</MenuItem>
                <MenuItem value="hindi">Hindi</MenuItem>
              </Select>
            </FormControl>
            <Button
              onClick={handleDownloadLocalization}
              variant="contained"
              color="info"
              style={{ marginRight: "20px", color: "white" }}
            >
              Download {gameMapping[game]} {languageMapping[language]}{" "}
              Localization File
            </Button>
            <Button onClick={() => openLocalizationFileSelector()}>
              Upload {gameMapping[game]} {languageMapping[language]}{" "}
              Localization File
            </Button>
            {localizationFilesContent.map((file, index) => (
              <p key={index} style={{ marginBottom: "0.5rem" }}>
                {file.name}
              </p>
            ))}
            <Button
              disabled={localizationDisabled}
              onClick={onSaveLocalization}
              variant="contained"
            >
              Upload
            </Button>
          </Box>
        </Paper>

        <h2
          style={{
            fontFamily: "Playfair Display",
            marginTop: "12px",
            marginLeft: "10px",
          }}
        >
          Profanity
        </h2>
        <Paper
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 0.1, width: "25ch" },
            padding: "35px",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            mt={4}
            mb={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              onClick={handleDownloadProfanity}
              variant="contained"
              color="info"
            >
              Download Profanity File
            </Button>
            <Button onClick={() => openProfanityFileSelector()}>
              Upload Latest Profanity File
            </Button>
            {profanityFilesContent.map((file, index) => (
              <p key={index} style={{ marginBottom: "0.5rem" }}>
                {file.name}
              </p>
            ))}
            <Button
              disabled={profanityDisabled}
              onClick={onSaveProfanity}
              variant="contained"
            >
              Upload
            </Button>
          </Box>
        </Paper>
      </div>
    </>
  );
}
