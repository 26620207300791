import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  usePermissions,
  NumberField,
  FunctionField,
  FilterForm,
  DateTimeInput,
  SelectArrayInput,
  FilterButton,
} from "react-admin";
import Big from "big.js";
import UserNameHyperlink from "../../../components/UserNameHyperlink";
import { Link } from "react-router-dom";
import { Stack } from "rsuite";
import { Typography } from "@mui/material";

const CbrGameHistory = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <SelectArrayInput
        label="Filter by Outcome"
        source="outcome"
        choices={[
          { id: "won", name: "Won" },
          { id: "lost", name: "Lost" },
        ]}
      />,
      <DateTimeInput source="startDate" alwaysOn />,
      <DateTimeInput source="endDate" alwaysOn />,
    ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );


  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search User Name"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Join Amount"
        source="joinFee"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Table ID"
        source="tableId"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const valueFormatter = (value: any) => {
    if (typeof value !== 'undefined' && value !== null && !isNaN(value)) {
      const formattedValue = new Big(value);
      return formattedValue.toFixed(2);
    }
    return '';
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>CB Game Histories</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink source="userName" to="userId" label="User Name" />
          <TextField source="tableId" label="Table Id" />
          <TextField source="joinFee" label="Join Fee" />
          <NumberField
            source="winLoseAmount"
            label="Win/Loss Amount"
          />
          <TextField source="totalScore" label="Total Score" />
          <FunctionField
            label="Scores"
            render={(record: any) => (
              <div>
                {record.scores.map((score: string, index: number) => (
                  <div key={index} style={{ marginBottom: '8px', marginTop: '8px' }}>
                    <span style={{ fontWeight: "bold" }}>{`R${index + 1}: `}</span>
                    <span>{score}</span>
                  </div>
                ))}
              </div>
            )}
          />
          <TextField source="outcome" label="Outcome" />
          <DateField source="startedAt" showTime label="Started at" />
          <DateField source="updatedAt" showTime label="Updated at" />
        </DatagridConfigurable>
      </List>
    </>
  );

};

export default CbrGameHistory;