import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import {
  get,
  includes,
  isEmpty,
  isEqual,
  pickBy,
  sumBy,
  toNumber,
  toString,
  map,
  has,
} from "lodash";
import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { TournamentDataType, WinningPrizesType } from "../tournaments/tournamentTypes";
import { callRestApi } from "../../utils/callRestApi";
import { useParams } from "react-router";
import { AxiosResponse } from "axios";
import { MegaTournamentDataType } from "./megaTournamentTypes";

const EditMegaTournament = () => {
  const [tournamentData, setTournamentData] = useState();
  const [winningPriceRef, setWinningPriceRef] = useState<WinningPrizesType[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    control,
    getValues,
    setValue,
  } = useForm<MegaTournamentDataType>({
    defaultValues: {
      maxTotalEntries: 2,
      maxEntriesPerUser: 2,
      maxExtensionLimit: 2,
      extensionTime: 2,
      totalMoves: 36,
      winningPrizes: [
        {
          minRank: 1,
          maxRank: 1,
          amount: "1",
          percentage: 0,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "winningPrizes",
    control,
  });

  const { id } = useParams();

  const watchingWinningPrice = useWatch({ control, name: "winningPrizes" });
  const watchingJoinFee = useWatch({ control, name: "joinFee" });
  const watchMaxTotalEntries = useWatch({ control, name: "maxTotalEntries" });

  useEffect(() => {
    if (!isEmpty(id)) {
      fetchTournamentDetails(id || "");
    }
    // eslint-disable-next-line
  }, [id]);

  // This will set the updated value to state if there is any changes in winningprize inputs
  useEffect(() => {
    if (!isEqual(winningPriceRef, watchingWinningPrice)) {
      setWinningPriceRef(watchingWinningPrice);
    }
    // eslint-disable-next-line
  }, [watchingWinningPrice]);

  // This will update and set the calculated values to readonly form fields
  useEffect(() => {
    updateWinningPriceFields();
    // eslint-disable-next-line
  }, [winningPriceRef, watchingJoinFee, watchMaxTotalEntries]);

  const updateWinningPriceFields = () => {
    const formValues = getValues() as MegaTournamentDataType;
    const { winningPrizes } = formValues;

    // Update Number of Winners
    // setValue(
    //   "noOfWinners",
    //   sumBy(
    //     winningPrizes,
    //     (data: WinningPrizesType) => data.maxRank - data.minRank + 1
    //   )
    // );

    // // Update Total Percentage
    // setValue(
    //   "totalPercentage",
    //   Number.isNaN(
    //     sumBy(
    //       winningPrizes,
    //       (data: WinningPrizesType) => data?.totalWinnerPercentage || 0
    //     )
    //   )
    //     ? 0
    //     : sumBy(
    //         winningPrizes,
    //         (data: WinningPrizesType) => data?.totalWinnerPercentage || 0
    //       )
    // );

    // // Update Total Overall Amount
    // setValue(
    //   "totalOverAllAmount",
    //   sumBy(
    //     winningPrizes,
    //     (data: WinningPrizesType) => data.totalWinnerAmount || 0
    //   )
    // );

    winningPrizes.forEach((winningPrice: WinningPrizesType, index: number) => {
      // Update Percentage
      setValue(
        `winningPrizes.${index}.percentage`,
        Number.isNaN(
          (parseFloat(winningPrice.amount) /
            (parseFloat(formValues.joinFee) * formValues.maxTotalEntries)) *
          100
        )
          ? 0
          : (parseFloat(winningPrice.amount) /
            (parseFloat(formValues.joinFee) * formValues.maxTotalEntries)) *
          100
      );
      // Update Total Winner Percentage
      setValue(
        `winningPrizes.${index}.totalWinnerPercentage`,
        Number.isNaN(
          (winningPrice.maxRank - winningPrice.minRank + 1) *
          winningPrice.percentage
        )
          ? 0
          : (winningPrice.maxRank - winningPrice.minRank + 1) *
          winningPrice.percentage
      );
      // Update Total Winner Amount
      setValue(
        `winningPrizes.${index}.totalWinnerAmount`,
        (winningPrice.maxRank - winningPrice.minRank + 1) *
        parseFloat(winningPrice.amount)
      );
      // Update total winner number
      setValue(
        `winningPrizes.${index}.totalWinnersNumber`,
        winningPrice.maxRank - winningPrice.minRank + 1
      );
    });
  };

  const fetchTournamentDetails = async (id: string) => {
    setLoading(true);
    try {
      const { data } = (await callRestApi(
        `/ludo/tournament/${id}`,
        "GET",
        {}
      )) as AxiosResponse;

      if (!isEmpty(data)) {
        data.startAt = dayjs(data.startAt).format("YYYY-MM-DD HH:mm");
        reset(data);
        setTournamentData(data);
      }
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  const onSubmit = async (data: MegaTournamentDataType) => {
    const differenceData: any = pickBy(
      data,
      (value: string, key: string) => !isEqual(value, get(tournamentData, key))
    );

    const dataKeys = Object.keys(differenceData);
    const payload: any = { ...differenceData };

    if (includes(dataKeys, "startAt")) {
      payload.startAt = dayjs(differenceData.startAt).toISOString();
    }
    if (includes(dataKeys, "maxNoPlayers")) {
      payload.maxNoPlayers = toNumber(toString(differenceData.maxNoPlayers));
    }
    if (includes(dataKeys, "minNoPlayers")) {
      payload.minNoPlayers = toNumber(toString(differenceData.minNoPlayers));
    }
    if (includes(dataKeys, "isRepeatable")) {
      payload.isRepeatable = JSON.parse(toString(differenceData.isRepeatable));
    }
    if (includes(dataKeys, "isAutomatic")) {
      payload.isAutomatic = JSON.parse(toString(differenceData.isAutomatic));
    }
    if (includes(dataKeys, "featured")) {
      payload.featured = JSON.parse(toString(differenceData.featured));
    }
    if (includes(dataKeys, "withCustomSound")) {
      payload.withCustomSound = JSON.parse(toString(differenceData.withCustomSound));
    }
    if (includes(dataKeys, "winningPrizes")) {
      payload.winningPrizes = map(
        differenceData.winningPrizes,
        (winningPrizeData) => {
          if (has(winningPrizeData, "minRank")) {
            winningPrizeData.minRank = toNumber(winningPrizeData.minRank);
          }
          if (has(winningPrizeData, "maxRank")) {
            winningPrizeData.maxRank = toNumber(winningPrizeData.maxRank);
          }
          return winningPrizeData;
        }
      );
    }

    try {
      setLoading(true);
      const { status } = (await callRestApi(
        `/ludo/tournament/${id}`,
        "PUT",
        payload
      )) as AxiosResponse;
      if (status === 200) {
        toast("Tournament Updated", {
          type: "success",
        });
      }
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card style={{ padding: "25px" }}>
      <h4>Edit Tournament</h4>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="name">Tournament Name</InputLabel>
              <TextField
                {...register("name", { required: "Name is required" })}
                id="name"
                name="name"
                placeholder="Name"
                fullWidth
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="maxTotalEntries">
                Max Entries
              </InputLabel>
              <TextField
                {...register("maxTotalEntries", {
                  required: "Max Entries is required",
                })}
                id="maxTotalEntries"
                name="maxTotalEntries"
                placeholder="Enter Max Total Entries"
                type="number"
                fullWidth
                error={!!errors.maxTotalEntries}
                helperText={errors.maxTotalEntries && errors.maxTotalEntries.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="maxEntriesPerUser">
                Max Entries Per User
              </InputLabel>
              <TextField
                {...register("maxEntriesPerUser", {
                  required: "Max Entries Per User is required",
                })}
                id="maxEntriesPerUser"
                name="maxEntriesPerUser"
                placeholder="Enter Max Entries per User"
                type="number"
                fullWidth
                error={!!errors.maxEntriesPerUser}
                helperText={errors.maxEntriesPerUser && errors.maxEntriesPerUser.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="totalMoves">
              Total Moves
              </InputLabel>
              <TextField
                {...register("totalMoves", {
                  required: "Max Entries Per User is required",
                })}
                id="totalMoves"
                name="totalMoves"
                placeholder="Enter Total Moves"
                type="number"
                fullWidth
                error={!!errors.totalMoves}
                helperText={errors.totalMoves && errors.totalMoves.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="alias">Alias Name</InputLabel>
              <TextField
                {...register("alias", {
                })}
                id="alias"
                name="alias"
                placeholder="Alias Name"
                fullWidth
                error={!!errors.alias}
                helperText={errors.alias && errors.alias.message}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Box display="flex" justifyContent="end">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  append({
                    minRank: 1,
                    maxRank: 1,
                    amount: "1",
                    percentage: 0.1,
                  })
                }
              >
                Add Winning Detail
              </Button>
            </Box>
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            {fields.map((field, index) => {
              return (
                <Grid
                  item
                  key={field.id}
                  xs={12}
                  md={2}
                  style={{ marginBottom: "5px" }}
                >
                  <Card style={{ padding: "10px" }}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" justifyContent="space-between">
                        <h5>Winning Prize</h5>
                        {index > 0 && (
                          <HighlightOff
                            onClick={() => remove(index)}
                            color="error"
                          />
                        )}
                      </Box>

                      <TextField
                        label="Minimum Rank"
                        {...register(
                          `winningPrizes.${index}.minRank` as const,
                          {
                            required: "Minimum Rank is required field",
                            pattern: /^\d+$/,
                          }
                        )}
                        error={
                          get(errors, `winningPrizes.${index}.minRank`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.minRank.message`,
                          ""
                        )}
                        type="number"
                      />
                      <TextField
                        label="Maximum Rank"
                        {...register(
                          `winningPrizes.${index}.maxRank` as const,
                          {
                            required: "Maximum Rank is required field",
                            pattern: /^\d+$/,
                          }
                        )}
                        error={
                          get(errors, `winningPrizes.${index}.maxRank`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.maxRank.message`,
                          ""
                        )}
                        type="number"
                      />
                      <TextField
                        label="Amount"
                        {...register(`winningPrizes.${index}.amount`, {
                          required: "Amount is required field",
                        })}
                        error={
                          get(errors, `winningPrizes.${index}.amount`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.amount.message`,
                          ""
                        )}
                        type="text"
                      />
                      <TextField
                        label="Percentage"
                        {...register(`winningPrizes.${index}.percentage`, {
                          required: "Percentage is required field",
                        })}
                        error={
                          get(errors, `winningPrizes.${index}.percentage`)
                            ? true
                            : false
                        }
                        helperText={get(
                          errors,
                          `winningPrizes.${index}.percentage.message`,
                          ""
                        )}
                        type="text"
                        inputProps={{
                          step: "0.1",
                        }}
                      />

                      <TextField
                        label="Total Amount"
                        {...register(
                          `winningPrizes.${index}.totalWinnerAmount`
                        )}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Total Percentage"
                        {...register(
                          `winningPrizes.${index}.totalWinnerPercentage`
                        )}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Total Winners"
                        {...register(
                          `winningPrizes.${index}.totalWinnersNumber`
                        )}
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <br />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box display="flex" justifyContent="space-between">
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={() => reset(tournamentData)}
                style={{ maxWidth: "50%" }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                style={{ maxWidth: "50%" }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </form>
      </Box>
    </Card>
  );
};
export default EditMegaTournament;