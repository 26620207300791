export const convertToDotNotation = (obj: any, parentKey = ""): object => {
  const dotNotationObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const val = obj[key];
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (Array.isArray(val)) {
        dotNotationObj[newKey] = val;
      } else if (typeof val === "object") {
        const nestedObj = convertToDotNotation(val, newKey);
        Object.assign(dotNotationObj, nestedObj);
      } else {
        dotNotationObj[newKey] = val;
      }
    }
  }
  return dotNotationObj;
};
