import {
  List,
  TextField,
  DateField,
  ChipField,
  DatagridConfigurable,
  Filter,
  SearchInput,
  SelectArrayInput,
  FilterForm,
  usePermissions,
  SelectColumnsButton,
  FilterButton,
  TopToolbar,
  ExportButton,
  DateTimeInput,
} from "react-admin";
import { Stack } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";

const transactionFilters = [
  <SelectArrayInput
    label="Filter by Type"
    source="type"
    choices={[
      { id: "signupBonus", name: "Signup Bonus" },
      { id: "coupon", name: "Coupon" },
    ]}
  />,
  <DateTimeInput source="startDate" alwaysOn />,
  <DateTimeInput source="endDate" alwaysOn />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const Gametransactions = (props: any) => {
  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Amount"
        source="amount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Bonus Transactions</h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="type" label="Type" />
          <TextField source="amount" />
          <DateField source="createdAt" showTime label="Created At" />
          <DateField source="updatedAt" showTime label="Updated at" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Gametransactions;
