import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { getItemWithExpiry } from "../../../utils";
import { Link } from "react-router-dom";

interface PlayerRowData {
  userId: string;
  username: string;
  betAmount: number;
  cashoutAmount: number;
  avatar: number;
  roundNo: number;
  roundStartTime: string;
  roundStatus: string;
}

const AvtLiveGames = () => {
  const [loading, setLoading] = useState(true);
  const [usernameFilter, setUsernameFilter] = useState("");
  const [betAmountFilter, setBetAmountFilter] = useState("");
  const [data, setData] = useState<PlayerRowData[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [totalNumber, setTotalNumber] = useState(0);

  const columns: GridColDef[] = [
    {
      field: "roundNo",
      headerName: "Round No",
      flex: 1,
      renderCell: (params) => {
        const isFirstOccurrence = data.findIndex(
          (item) => item.roundNo === params.row.roundNo
        ) === data.findIndex((item) => item.userId === params.row.userId);

        return isFirstOccurrence ? params.value : "";
      },
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      renderCell: (params) => {
        const isFirstOccurrence = data.findIndex(
          (item) => item.username === params.row.username
        ) === data.findIndex((item) => item.userId === params.row.userId);
    
        return isFirstOccurrence ? (
          <div>
            <Link to={`/api/users/${params.row.userId}/show`}>
              {params.row.username}
            </Link>
          </div>
        ) : (
          ""
        );
      },
    },
    {
      field: "betAmount",
      headerName: "Bet Amount",
      flex: 1,
      renderCell: (params) => `₹${params.value}`,
    },
    {
      field: "cashoutAmount",
      headerName: "Cashout Amount",
      flex: 1,
      renderCell: (params) => `₹${params.value}`,
    },
    {
      field: "roundStatus",
      headerName: "Round Status",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.value.toLowerCase() === "started" ? "green" : params.value.toLowerCase() === "waiting" ? "red" : "inherit",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
    },
  ];

  const fetchGameData = async () => {
    try {
      setLoading(true);
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      const { page, pageSize } = paginationModel;
      let query = "";
      query += usernameFilter ? `&userName=${usernameFilter}` : "";
      query += betAmountFilter ? `&betAmount=${betAmountFilter}` : "";
      query += `&skip=${pageSize * page}&count=${pageSize}`;

      const response = await axios.get(`/admin/live-games/aviator?key=YOUR_MAINTENANCE_BYPASS_KEY${query}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response?.data?.players?.length) {
        const playerData: PlayerRowData[] = response.data.players.map((player: any) => ({
          ...player,
          roundNo: response.data.roundNo,
          roundStartTime: response.data.roundStartTime,
          roundStatus: response.data.roundStatus,
        }));
        setData(playerData);
        setTotalNumber(response.data.totalNumber); // Set to totalNumber from API response
      } else {
        setData([]);
        setTotalNumber(0);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching game data:", error);
      setLoading(false);
      toast.error("Error fetching game data");
    }
  };

  useEffect(() => {
    fetchGameData();
  }, [usernameFilter, betAmountFilter, paginationModel]);

  return (
    <>
      <h2>Aviator Live Games</h2>
      <p>Total Live Users: {totalNumber}</p> {/* Display total number of users */}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Search with Username"
            variant="outlined"
            fullWidth
            value={usernameFilter}
            onChange={(e) => setUsernameFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Search with Bet Amount"
            variant="outlined"
            fullWidth
            value={betAmountFilter}
            onChange={(e) => setBetAmountFilter(e.target.value)}
          />
        </Grid>
      </Grid>

      <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
        {data.length === 0 ? (
          <p>No ongoing rounds available.</p>
        ) : (
          <DataGrid
            columns={columns}
            rows={data}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 20, 50]}
            rowCount={totalNumber} 
            paginationMode="server"
            autoHeight
            getRowId={(row) => row.userId}
          />
        )}
      </Paper>
    </>
  );
};

export default AvtLiveGames;
