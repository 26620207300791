import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
  Link,
  useListContext,
} from "react-admin";
import { Stack, Typography } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";
import { useState } from "react";

const Withdrawals = (props: object) => {
  const { permissions } = usePermissions();
  const [liveUsersCount, setLiveUsersCount] = useState<any>();
  const [liveGamesCount, setLiveGamesCount] = useState<any>();

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "started", name: "Started" },
        { id: "gameDiscarded", name: "GameDiscarded" },
        { id: "completed", name: "Completed" },
      ]}
    />,
    <SelectArrayInput
      label="Filter by GameType"
      source="gameType"
      choices={[
        { id: "quick", name: "Quick" },
        { id: "furious4", name: "Furious4" },
        { id: "classic", name: "Classic" },
      ]}
    />,

    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => {
    return (
      <TopToolbar>
        <FilterButton filters={transactionFilters} />
        <SelectColumnsButton />
        {permissions.includes("admin") && <ExportButton />}
      </TopToolbar>
    );
  };

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search User Name"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Join Fee"
        source="joinFee"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Table Id"
        source="tableId"
        resettable
        alwaysOn
      />
    </Filter>
  );
  const renderWinner = (record: any) => {
    if (record.winner) {
      let winners;
      try {
        winners = JSON.parse(record.winner);
      } catch (error) {
        return `Winner: ${record.winner}`;
      }
  
      if (Array.isArray(winners) && winners.length > 0) {
        return `Winners: ${winners.join(", ")}`;
      } else {
        return "N/A";
      }
    } else {
      return "No winner";
    }
  };
  

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Ludo Game Histories</h2>
      <div
        style={{
          fontWeight: "bold",
          color: "black",
          marginTop: "5px",
          paddingLeft: "0%",
          whiteSpace: "pre",
        }}
      >
        {" "}
        Live Games: {liveGamesCount}
        {"   "}
        Live Users: {liveUsersCount}
      </div>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="tableId" label="Table ID" />
          <FunctionField
            label="User Names"
            render={(record: any) => {
              return (
                <div>
                  {record.players.map((player: any) => (
                    <div
                      key={player.userId}
                      style={{ marginBottom: "8px", marginTop: "8px" }}
                    >
                      {`${player.playerId}:`}
                      <Link to={`/api/users/${player.userId}/show`}>
                        {" "}
                        {` ${player.username}`}
                      </Link>
                    </div>
                  ))}
                </div>
              );
            }}
          />
          <TextField source="gameType" />
          <FunctionField
            label="Win Amount"
            render={(record: any) => {
              if ("liveUsersCount" in record) {
                setLiveUsersCount(record.liveUsersCount);
              }
              return record.winAmount;
            }}
          />
          <FunctionField
            // label="Win Amount Percentage"
            render={(record: any) => {
              if ("liveGamesCount" in record) {
                setLiveGamesCount(record.liveGamesCount);
              }
              return record.winAmountPercentage;
            }}
          />{" "}
          <TextField source="joinFee" />
          <FunctionField
            label="Status"
            render={(record: any) => {
              if (record.status === "started") {
                return <ChipField source="status" color="warning" />;
              } else if (record.status === "completed") {
                return <ChipField source="status" color="success" />;
              } else if (record.status === "gameDiscarded") {
                return <ChipField source="status" color="error" />;
              } else {
                return <ChipField source="status" color="info" />;
              }
            }}
          />
          <FunctionField label="Outcome" render={renderWinner} />
          <DateField source="createdAt" showTime label="Created at" />
          <DateField source="updatedAt" showTime label="Updated at" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Withdrawals;
