import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, usePermissions, useRefresh } from "react-admin";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getItemWithExpiry } from "../../../utils";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { callRestApi } from "../../../utils/callRestApi";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  Switch,
} from "@mui/material";
import { ReactQueryDevtools } from "react-query/devtools";
import moment from "moment-timezone";

interface Player {
  playerId: string;
  userId: string;
  name: string;
  username: string;
  didLeave: boolean;
}

interface GameTable {
  [x: string]: any;
  tableId: string;
  joinFee: string;
  status: string;
  players: Player[];
  startedAt: string;
  updatedAt: string;
}

const GameOngoing = () => {
  const refresh = useRefresh();
  const [loading, setLoading] = useState(true);
  const [tableIdFilter, setTableIdFilter] = useState("");
  const [usernameFilter, setUsernameFilter] = useState("");
  const [amountFilter, setAmountFilter] = useState("");
  const [totalLivePlayers, setTotalLivePlayers] = useState(0);
  const [totalTables, setTotalTables] = useState(0);
  const [filteredData, setFilteredData] = useState<GameTable[]>([]);
  const [tableStatus, setTableStatus] = useState("green");
  const [clearConfirmation, setClearConfirmation] = useState(false);
  const [waitingTableCount, setWaitingTableCount] = useState(0);
  const [waitingPlayerCount, setWaitingPlayerCount] = useState(0);
  const [showWaitingTables, setShowWaitingTables] = useState(false);
  const [showStuckTables, setShowStuckTables] = useState(false);
  const [tableIdToDelete, setTableIdToDelete] = useState<string | null>(null);
  const [stuckTablesCount, setStuckTablesCount] = useState(0);
  const { permissions } = usePermissions();
  const isAdmin = permissions.includes("admin");

  const openClearConfirmation = (tableId: string) => {
    setTableIdToDelete(tableId);
    setClearConfirmation(true);
  };

  const closeClearConfirmation = () => {
    setClearConfirmation(false);
  };

  const handleClearConfirmation = async (confirmed: boolean) => {
    closeClearConfirmation();
    if (confirmed && tableIdToDelete) {
      await handleClearStuckTable(tableIdToDelete);
    }
  };

  const handleClearStuckTable = async (tableId: string) => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }
      const endpoint = `/admin/live-games/snakesandladders/${tableId}`;
      await callRestApi(endpoint, "DELETE", {});

      toast.success("Table deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      refresh();
    } catch (e: any) {
      toast(
        e?.response.data?.message ? e?.response.data?.message : e?.message,
        { type: "error" }
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: "tableId",
      headerName: "Table ID",
      flex: 2,
      renderCell: (params) => {
        if (params.row.status === "waiting") {
          return null;
        }
        return (
          <div>
            {params.row.status === "ongoing" ? (
              <span>{params.row.tableId}</span>
            ) : (
              <Link
                to={`/game/${params.row.tableId}`}
                style={{
                  color: params.row.status === "stuck" ? "red" : "",
                }}
              >
                {params.row.tableId}
              </Link>
            )}
            {params.row.status === "stuck" &&
              renderClearButton(params.row.tableId)}
          </div>
        );
      },
    },
    {
      field: "joinFee",
      headerName: "Join Amount",
      flex: 1,
      renderCell: (params) => (
        <div>
          ₹{params.value}
        </div>
      ),
    },
    {
      field: "totalNumber",
      headerName: "Total Number",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor:
              params.row.status === "ongoing"
                ? "green"
                : params.row.status === "waiting"
                ? "darkcyan"
                : "red",
            borderRadius: "5px",
            padding: "5px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "white",
              fontWeight: "bold",
            }}
          >
            {params.row.status}
          </span>
        </div>
      ),
    },
    {
      field: "players",
      headerName: "Players",
      flex: 3,
      renderCell: (params) => (
        <div>
          {params.row.status === "waiting" ? (
            // Render waiting users
            params.row.users?.map((user: any) => (
              <div key={user.userId} style={{ padding: "5px" }}>
                <Link to={`/api/users/${user.userId}/show`}>
                  {user.username}
                </Link>
              </div>
            ))
          ) : (
            // Render players for ongoing games
            params.row.players?.map((player: Player) => (
              <div key={player.userId} style={{ padding: "5px" }}>
                <Link to={`/api/users/${player.userId}/show`}>
                  {player.username}
                </Link>
                {player.didLeave && (
                  <span style={{ color: "red", marginLeft: "5px" }}>(Left)</span>
                )}
              </div>
            ))
          )}
        </div>
      ),
    },
    {
      field: "startedAt",
      headerName: "Started At",
      flex: 2,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 2,
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const fetchGameData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      const filters = {
        userName: usernameFilter,
        tableId: tableIdFilter,
        amount: amountFilter,
      };

      const { page, pageSize } = paginationModel;
      const query = `skip=${pageSize * page}&count=${pageSize}`;
      const endpoint = "/admin/live-games/snakesandladders";
      const response = await callRestApi(endpoint, "POST", filters, query);

      if (!response?.data || Object.keys(response.data).length === 0) {
        setLoading(false);
        return;
      }

      setTotalTables(response?.data.totalTableCount);
      setTotalLivePlayers(response?.data.totalLiveUserCount);
      setWaitingTableCount(response?.data.totalWaitingTableCount);
      setWaitingPlayerCount(response?.data.totalWaitingUserCount);
      setStuckTablesCount(response?.data.totalStuckTableCount);

      let allTables: GameTable[] = [];
      const joinFeeTotals: Record<string, number> = {};

      response.data.gameTables.forEach((tableGroup: any) => {
        tableGroup.tables.forEach((table: any) => {
          if (table.status === "ongoing") {
            const key = table.joinFee;
            joinFeeTotals[key] = (joinFeeTotals[key] || 0) + 1;
          }
        });
        allTables = allTables.concat(tableGroup.tables);
      });

      response.data.stuckTables.forEach((tableGroup: any) => {
        allTables = allTables.concat(tableGroup.tables);
      });
      const waitingUsers = response.data.waitingUserList.flatMap((waitingGroup: any) => {
        if (waitingGroup.totalNumber > 0) {
          return [{
            tableId: `waiting-${waitingGroup.joinFee}`,
            joinFee: waitingGroup.joinFee,
            status: "waiting",
            users: waitingGroup.players,
            startedAt: "N/A",
            updatedAt: "N/A",
            totalNumber: waitingGroup.totalNumber,
          }];
        }
        return [];
      });

      allTables = allTables.map((table) => ({
        ...table,
        totalNumber:
          table.status === "ongoing"
            ? joinFeeTotals[table.joinFee] || 0
            : table.totalNumber,
        startedAt:
          table.startedAt !== "N/A"
            ? moment(table.startedAt)
                .tz("Asia/Kolkata")
                .format("DD/MM/YYYY, HH:mm:ss")
            : table.startedAt,
        updatedAt:
          table.updatedAt !== "N/A"
            ? moment(table.updatedAt)
                .tz("Asia/Kolkata")
                .format("DD/MM/YYYY, HH:mm:ss")
            : table.updatedAt,
      }));

      allTables = allTables.concat(waitingUsers);

      if (
        tableIdFilter ||
        usernameFilter ||
        amountFilter ||
        showWaitingTables ||
        showStuckTables
      ) {
        allTables = allTables.filter((table) => {
          const matchesTableId = tableIdFilter
            ? table.tableId.includes(tableIdFilter)
            : true;
          const matchesUsername = usernameFilter
            ? table.players.some((player) =>
                player.username.includes(usernameFilter)
              )
            : true;
          const matchesAmount = amountFilter
            ? table.joinFee === amountFilter
            : true;

          if (showWaitingTables && !showStuckTables) {
            return (
              matchesTableId &&
              matchesUsername &&
              matchesAmount &&
              table.status === "waiting"
            );
          } else if (!showWaitingTables && showStuckTables) {
            return (
              matchesTableId &&
              matchesUsername &&
              matchesAmount &&
              table.status === "stuck"
            );
          } else if (!showWaitingTables && !showStuckTables) {
            return matchesTableId && matchesUsername && matchesAmount;
          } else {
            return (
              matchesTableId &&
              matchesUsername &&
              matchesAmount &&
              (table.status === "waiting" || table.status === "stuck")
            );
          }
        });
      }

      setFilteredData(allTables);
      if (allTables.length > 0) {
        setTableStatus("green");
      } else {
        setTableStatus("red");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching game data:", error);
      setLoading(false);
      toast.error("Error fetching game data");
    }
  };

  useEffect(() => {
    let interval: any;
    const timeout = setTimeout(() => {
      fetchGameData();
      interval = setInterval(async () => {
        await fetchGameData();
        refresh();
      }, 20000);
    }, 800);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [
    refresh,
    tableIdFilter,
    usernameFilter,
    amountFilter,
    showWaitingTables,
    showStuckTables,
    paginationModel,
  ]);

  const renderClearButton = (tableId: string) => {
    const buttonStyle = {
      marginLeft: "28px",
      padding: "6px 6px",
      backgroundColor: "#FF6500",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    };

    return isAdmin ? (
      <div>
        <button
          onClick={() => openClearConfirmation(tableId)}
          style={buttonStyle}
        >
          Clear
        </button>
        <Dialog open={clearConfirmation} onClose={closeClearConfirmation}>
          <DialogTitle>Confirm Clear Table</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to clear the table?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClearConfirmation(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleClearConfirmation(true)}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : null;
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>SNL Live Games</h2>

      <div>
        <div>
          <div style={{ marginBottom: "20px" }}>
            <StatusIndicator
              label="Live Tables"
              value={totalTables}
              color={tableStatus}
            />
            <StatusIndicator
              label="Live Players"
              value={totalLivePlayers}
              color={tableStatus}
            />
            <StatusIndicator
              label="Waiting Tables"
              value={waitingTableCount}
              color={tableStatus}
            />
            <StatusIndicator
              label="Waiting Players"
              value={waitingPlayerCount}
              color={tableStatus}
            />
            <StatusIndicator
              label="Stuck Tables"
              value={stuckTablesCount}
              color={stuckTablesCount > 0 ? "red" : "green"}
            />
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Search by Table ID"
                variant="outlined"
                fullWidth
                value={tableIdFilter}
                onChange={(e) => {
                  setPaginationModel({ page: 0, pageSize: 10 });
                  setTableIdFilter(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Search by UserName"
                variant="outlined"
                fullWidth
                value={usernameFilter}
                onChange={(e) => {
                  setPaginationModel({ page: 0, pageSize: 10 });
                  setUsernameFilter(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Search by Join Amount"
                variant="outlined"
                fullWidth
                value={amountFilter}
                onChange={(e) => {
                  setPaginationModel({ page: 0, pageSize: 10 });
                  setAmountFilter(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 0px 6px 0px",
              }}
            >
              <FormLabel component="legend">Show Waiting Tables</FormLabel>
              <Switch
                checked={showWaitingTables}
                onChange={() => setShowWaitingTables((prev) => !prev)}
              />
              <FormLabel component="legend">Show Stuck Tables</FormLabel>
              <Switch
                checked={showStuckTables}
                onChange={() => setShowStuckTables((prev) => !prev)}
              />
            </Grid>
            <DataGrid
              columns={columns}
              rows={filteredData}
              loading={loading}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[10, 20, 50]}
              rowCount={totalTables}
              paginationMode="server"
              autoHeight
              getRowHeight={() => "auto"}
              getRowId={(row) => row.tableId}
            />
          </Paper>
          <ReactQueryDevtools />
        </div>
      </div>
    </>
  );
};

const StatusIndicator = ({
  label,
  value,
  color,
}: {
  label: string;
  value: number;
  color: string;
}) => (
  <div style={{ display: "inline-block", marginRight: "20px" }}>
    <div
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: color,
        display: "inline-block",
        marginRight: "5px",
      }}
    />
    <b>
      {label}: {value}
    </b>
  </div>
);

export default GameOngoing;
