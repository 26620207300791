import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  usePermissions,
  NumberField,
  FunctionField,
  FilterForm,
  DateTimeInput,
} from "react-admin";
import Big from "big.js";
import UserNameHyperlink from "../../../components/UserNameHyperlink";
import { Link } from "react-router-dom";
import { Stack } from "rsuite";
import { Typography } from "@mui/material";

const RummyGameHistory = (props: object) => {
  const { permissions } = usePermissions();

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListActions = () => (
    <TopToolbar>
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search Point Value"
        source="tableType.pointValue"
        resettable
        alwaysOn
      />

      <SearchInput
        placeholder="Search Table ID"
        source="tableId"
        resettable
        alwaysOn
      />

      <SearchInput
        placeholder="Search Join Fee"
        source="joinFee"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const formatCurrency = (value: number | string | undefined) => {
    if (value === undefined || value === null) {
      return "₹0.00";
    }
  
    if (typeof value === "string") {
      value = parseFloat(value);
    }
    
    return `₹${value.toFixed(2)}`;
  };
  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Rummy Game Histories</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <FunctionField
            label="Table Id"
            render={(record: any) => (
              <Link to={record?.tableId}>{record?.tableId}</Link>
            )}
          />
          ;
          <FunctionField
            label="Join Fee"
            render={(record: any) => formatCurrency(record.joinFee)}
          />
          <TextField source="tableType.pointValue" label="Point Value" />
          <DateField source="createdAt" showTime label="Created At" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default RummyGameHistory;
