import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
    useTheme,
} from "@mui/material";
import { formatCurrencyWithCommas } from "../utils/currencyFormatter";

const StatsCard = (props: any) => {

  const theme = useTheme(); // Access the currently selected theme
  const isDarkTheme = theme.palette.mode === 'dark'; // Check if the dark theme is selected

  const { title, value, info, icon, isAmount ,value2} = props;
  return (
    <Card
      className="stats-card"
      style={{
        width: "95%",
        height: "90%",
        backgroundColor: "#F8F8F8",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
        padding: "0px 10px 0px 10px",
        borderRadius: "8px",
        transition: "transform 0.3s",
      }}
    >
      <CardContent>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
          >
            {icon}
            <CardHeader
              style={{ textAlign: "left", fontVariant: "h6" }}
              title={title}
              sx={{ fontWeight: "bold", marginLeft: "8px", color: isDarkTheme ? "#121212" : undefined }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Typography variant="h6" overflow="auto" style={{ color: isDarkTheme ? "#121212" : undefined }}>
              {isAmount && "₹"}
              {formatCurrencyWithCommas(value)}
            </Typography>
            {value2 && (
              <>
                <span style={{ margin: '0 8px' }}></span> {/* Add a separator (you can customize this) */}
                <Typography variant="h6" overflow="auto" style={{ color: isDarkTheme ? "#121212" : undefined }}>
                  {/* Add formatting logic if needed */}
                  {value2}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </CardContent>
      {info && (
        <CardActions>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
          >
            <InfoOutlined style={{ fontSize: "18px", color: "gray" }} />
            <Typography style={{ fontSize: "12px", color: isDarkTheme ? "#121212" : undefined }} color="gray">
              {info}
            </Typography>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

export default StatsCard;

const styles = `
  .stats-card {
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  .stats-card:hover {
    transform: scale(1.05);
  }

  @media (min-width: 600px) {
    .stats-card {
      width: calc(50% - 16px);
    }
  }

  @media (min-width: 960px) {
    .stats-card {
      width: calc(33.33% - 16px);
    }
    
    /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .stats-card {
    width: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .stats-card {
    width: 100%;
  }
}
  }
`;

const styleElement = document.createElement("style");
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);
