import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  FunctionField,
  usePermissions,
  DateTimeInput,
} from "react-admin";

import { Stack, Typography } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";

const payoutStatusChoices = [
  { id: "SUCCESS", name: "Success" },
  { id: "FAILED", name: "Failed" },
  { id: "PENDING", name: "Pending" },
];
const transactionFilters = [
  <SelectArrayInput
    label="Filter by Status"
    source="status"
    choices={[
      { id: "success", name: "Success" },
      { id: "failed", name: "Failed" },
      { id: "pending", name: "Pending" },
      { id: "refund", name: "Refund" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by Payment Gateway"
    source="gateway"
    choices={[
      { id: "juspay", name: "JusPay" },
      { id: "cashfree", name: "CashFree" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by Mode"
    source="mode"
    choices={[
      { id: "deposit", name: "Deposit" },
      { id: "withdrawal", name: "Withdrawal" },
      { id: "withdrawalRefund", name: "Withdrawal Refund" },
      { id: "admin", name: "Admin" },
    ]}
  />,

  <DateTimeInput source="startDate" alwaysOn />,
  <DateTimeInput source="endDate" alwaysOn />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const WalletTransactions = (props: any) => {
  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search OrderID"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search TransferId"
        source="_id"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Payment Transactions</h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <FunctionField
            label="TransferId"
            render={(record: any) => {
              if (["withdrawal", "withdrawalRefund"].includes(record.mode))
                return <TextField source="_id" label="TransferId" />;
            }}
          />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="amount" />
          <TextField source="mode" />
          <TextField source="gateway" />

          <FunctionField
            label="Status"
            render={(record: any) => {
              if (record.status === "pending") {
                return <ChipField source="status" color="warning" />;
              } else if (record.status === "success") {
                return <ChipField source="status" color="success" />;
              } else {
                return <ChipField source="status" color="error" />;
              }
            }}
          />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default WalletTransactions;
