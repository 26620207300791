import { Grid } from "@mui/material";

const UserInfoRow = (props: any) => {
  const { label = "", value = "" } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="baseline"
      paddingX={3}
    >
      <Grid item>
        <h5>{label}</h5>
      </Grid>
      <Grid item>{value}</Grid>
    </Grid>
  );
};

export default UserInfoRow;
